import { Settings, VerticalShadesOutlined } from '@mui/icons-material';
import Product1 from '../assets/images/products/product-1.jpg';
import Product2 from "../assets/images/products/product-2.jpg";
import {
    Chart as ChartJS,
    CategoryScale,
    ArcElement,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
const Dashboard = () => {
    const Testflowdata = {
        labels: [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ],
        datasets: [
            {
                label: 'TestFlow',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                borderColor: 'rgba(255, 99, 132, 0.5)',
                borderWidth: 1,
                data: [65, 59, 80, 81, 56, 55, 40, 75, 90, 60, 45, 70],
            },
        ],
    };
    const Testflowoptions = {
        responsive: true,
        plugins: {
            legend: {
                labels: {
                    color: 'white', // Set the label color for the 'TestFlow' dataset here
                },
                position: 'bottom',
            },
        },
        scales: {
            x: {
                beginAtZero: true,
                grid: {
                    display: false,
                },
                ticks: {
                    color: 'white', // Set the label color here
                },
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
            },
        },
    };
    const Cardiodata = {
        labels: [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ],
        datasets: [
            {
                label: 'Cardio',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                borderColor: 'rgba(53, 162, 235, 0.5)',
                borderWidth: 1,
                data: [10, 59, 80, 81, 56, 55, 40, 75, 90, 60, 45, 70],
            },
        ],
    };
    const Cardiooptions = {
        responsive: true,
        plugins: {
            legend: {
                labels: {
                    color: 'white', // Set the label color for the 'TestFlow' dataset here
                },
                position: 'bottom',
            },
        },
        scales: {
            x: {
                beginAtZero: true,
                grid: {
                    display: false,
                },
                ticks: {
                    color: 'white', // Set the label color here
                },
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
            },
        },
    };
    const data = {
        labels: ['Cardio', 'Testflow'],
        datasets: [
            {
                label: '# of Sales',
                data: [12, 19],
                backgroundColor: [
                    'rgba(53, 162, 235, 0.5)',
                    'rgba(255, 99, 132, 0.5)',
                ],
                borderColor: [
                    'rgba(53, 162, 235, 0.5)',
                    'rgba(255, 99, 132, 0.5)',

                ],
                borderWidth: 1,
            },
        ],
    };
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    color: 'white', // Set the label color here
                },
            },
        },
    };

    return (
        <div className="row">
            <div className="card bg-light-info shadow-none position-relative overflow-hidden">
                <div className="card-body px-4 py-3">
                    <div className="row align-items-center">
                        <div className="col-9">
                            <h4 className="fw-semibold mb-8">DashBoard</h4>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a className="text-muted " href="/home">Home</a></li>
                                    <li className="breadcrumb-item" aria-current="page">DashBoard</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-3">
                            <div className="text-center mb-n5">
                                <img alt="" className="img-fluid mb-n4" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-lg-4 d-flex align-items-stretch">
                <div className="card w-100">
                    <div className="card-body">
                        <h5 className="card-title fw-semibold">Overall Sale</h5>
                        <p className="card-subtitle mb-4">Overview</p>
                        <div className="chart-container" style={{ height: '200px', width: 'auto', display: "flex", justifyContent: "center" }}>
                            <Pie data={data} options={options} />
                        </div>
                        {/* <Pie data={data} options={options} /> */}
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-lg-4 d-flex align-items-stretch">
                <div className="card w-100">
                    <div className="card-body">
                        <h5 className="card-title fw-semibold">Cardio</h5>
                        <p className="card-subtitle mb-2">Every Month</p>
                        <div className="chart-container" style={{ height: '200px', width: 'auto', display: "flex", justifyContent: "center", marginTop: "23px" }}>

                            <Bar options={Cardiooptions} data={Cardiodata} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-lg-4 d-flex align-items-stretch">
                <div className="card w-100">
                    <div className="card-body">
                        <h5 className="card-title fw-semibold">TestFlow</h5>
                        <p className="card-subtitle mb-2">Every Month</p>
                        <div className="chart-container" style={{ height: '200px', width: 'auto', display: "flex", justifyContent: "center", marginTop: "23px" }}>

                            <Bar options={Testflowoptions} data={Testflowdata} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12 col-lg-12 d-flex align-items-stretch">
                <div className="card w-100">
                    <div className="card-body">
                        <div className="d-sm-flex d-block align-items-center justify-content-between mb-3">
                            <div className="mb-3 mb-sm-0">
                                <h5 className="card-title fw-semibold">Sales Information</h5>
                                <p className="card-subtitle"></p>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <select className="form-select fw-semibold">
                                        <option value={0} selected>Select Month</option>
                                        <option value={1}>Jan</option>
                                        <option value={2}>Feb</option>
                                        <option value={2}>Mar</option>
                                        <option value={2}>Apr</option>
                                        <option value={2}>May</option>
                                        <option value={2}>Jun</option>
                                        <option value={2}>Jul</option>
                                        <option value={2}>Aug</option>
                                        <option value={2}>Sep</option>
                                        <option value={2} selected>Oct</option>
                                        <option value={2}>Nov</option>
                                        <option value={2}>Dec</option>
                                    </select>
                                </div>
                                <div className="col">
                                    <select className="form-select fw-semibold">
                                        <option value={0}>Select Year</option>
                                        <option value={1} selected>2023</option>
                                        <option value={2}>2022</option>
                                    </select>
                                </div>

                                <div className="col">
                                    <select className="form-select fw-semibold">
                                        <option value={0} selected>All</option>
                                        <option value={1}>Cardio</option>
                                        <option value={2}>Test Flow</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table align-middle text-nowrap mb-0">
                                <thead>
                                    <tr className="text-muted fw-semibold">
                                        <th scope="col">Customer</th>
                                        <th scope="col">Contact Number</th>
                                        <th scope="col">Product & Versions</th>
                                        <th scope="col">Validity Period</th>
                                        <th scope="col">Options</th>
                                    </tr>
                                </thead>
                                <tbody className="border-top">
                                    <tr>
                                        <td >
                                            <h6 className="fw-semibold mb-1">James</h6>
                                            <p className="fs-2 mb-0 text-muted">Company Name</p>
                                        </td>
                                        <td>
                                            <p className="mb-0 fs-3 text-dark">9344013268</p>
                                        </td>
                                        <td>
                                            <p className="fs-3 text-dark mb-0">Cardio(V1.0.0) - Monthly Plan</p>
                                        </td>
                                        <td>
                                            <span className="badge fw-semibold bg-light-success text-success">(04-Oct to 04-Nov-2023)</span>
                                        </td>

                                        <td><a className="fs-6 text-muted" href="#" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Edit"><Settings /></a></td>

                                    </tr>
                                    <tr>
                                        <td >
                                            <h6 className="fw-semibold mb-1">Anderson</h6>
                                            <p className="fs-2 mb-0 text-muted">Company Name</p>
                                        </td>
                                        <td>
                                            <p className="mb-0 fs-3 text-dark">8344032861</p>
                                        </td>
                                        <td>
                                            <p className="fs-3 text-dark mb-0">TestFlow (V2.0.0) - Monthly Plan</p>
                                        </td>
                                        <td>
                                            <span className="badge fw-semibold bg-light-warning text-warning">(10-sep to 10-oct-2023)</span>
                                        </td>
                                        <td><a className="fs-6 text-muted" href="#" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Edit"><Settings /></a></td>

                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Dashboard;