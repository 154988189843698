import Product_Services from "../Api/Products_Services";
import ModalPopup from "../../Components/Common/ModalPopup";
import AddUpdateProducts from "./AddUpdateProducts";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { ArrowLeft, ArrowRight, Delete, Person2Outlined, RemoveRedEyeOutlined, Search, ShoppingBag } from "@mui/icons-material";

const prodObj = new Product_Services();
const ProductList = () => {
    const [getProductList, setProductList] = useState([]);
    const [productId, setproductId] = useState(0);
    const [productEditList, setProductEditList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0); // Current page
    const [pageNumber, setPageNumber] = useState(5);
    const itemsPerPage = parseInt(pageNumber); // Number 
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        get_ProductLIst()
    }, []);

    function formatApiDate(apiDate) {
        const dateObject = new Date(apiDate);

        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

        const dayName = dayNames[dateObject.getDay()];
        const monthName = monthNames[dateObject.getMonth()];
        const day = dateObject.getDate();
        const year = dateObject.getFullYear();

        return `${dayName}, ${monthName} ${day} ${year}`;
    }

    const get_ProductLIst = async () => {
        const res = await prodObj.getProductsList();
        if (res != null) {

            console.log(res);
            setProductList(res);
        }
    }
    const handleEdit = async (event, id) => {

        event.preventDefault()
        setproductId(id);
        const res = await prodObj.ProductsGetListById(id);
        if (res != null) {

            console.log(res);
            setProductEditList(res);
        }
    }
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const getItemsForCurrentPage = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return getProductList.slice(startIndex, endIndex);
    };
    const itemsToDisplay = getItemsForCurrentPage();
    const filteredProductList = itemsToDisplay.filter((product) =>
        product?.productName && product?.developer.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const handle_Delete = async (Id, Parameter) => {

        Swal.fire({
            title: 'Are you sure?',
            text: `Do you want ${Parameter == 'InActive' ? "Active" : "InActive"} this product?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, do it!',
            cancelButtonText: 'No, ignore'
        }).then((result) => {
            if (result.isConfirmed) {
                prodObj.Delete_Products(Id, Parameter).then((res) => {
                    if (res != null) {

                        toast.success(
                            `Product ${Parameter == 'InActive' ? "Active" : "InActive"} Succesfully`,
                            {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            }
                        );
                        setTimeout(() => {
                            window.location.reload();
                        }, 1500);
                    }
                    else {
                        toast.error(
                            'Failed to Save',
                            {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            }
                        );
                    }
                })

            }
        })


    }
    return (
        <>
            <div className="card bg-light-info shadow-none position-relative overflow-hidden">
                <div className="card-body px-4 py-3">
                    <div className="row align-items-center">
                        <div className="col-9">
                            <h4 className="fw-semibold mb-8">Products List</h4>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a className="text-muted " href="/home">Home</a></li>
                                    <li className="breadcrumb-item" aria-current="page">Products List</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-3">
                            <div className="text-center mb-n5">
                                <img alt="" className="img-fluid mb-n4" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="widget-content searchable-container list">
                {/* --------------------- start Contact ---------------- */}
                <div className="card card-body">
                    <div className="row">
                        <div className="col-md-4 col-xl-3">
                            <form className="position-relative">
                                <input type="text" className="form-control product-search ps-5" id="input-search" placeholder="Search Products..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                {/* <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3" /> */}
                                <Search className="position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3" />

                            </form>
                        </div>
                        <div className="col-md-8 col-xl-9 text-end d-flex justify-content-md-end justify-content-center mt-3 mt-md-0">
                            <div className="action-btn show-btn" style={{ display: 'none' }}>
                                <a href="#" className="delete-multiple btn-light-danger btn me-2 text-danger d-flex align-items-center font-medium">
                                    <i className="ti ti-trash text-danger me-1 fs-5" /> Delete All Row
                                </a>
                            </div>
                            <a href="#" id="btn-add-contact" className="btn btn-info d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#addproductModal" >
                                {/* <i className="ti ti-users text-white me-1 fs-5" /> */}
                                <ShoppingBag className="text-white me-1 fs-5" />
                                Add Products
                            </a>
                        </div>
                    </div>
                </div>
                {/* ---------------------
                              end Contact
                          ---------------- */}
                {/* Modal */}

                <div className="card card-body">
                    <div className="table-responsive">
                        <table className="table align-middle text-nowrap mb-0">
                            <thead className="header-item">
                                <tr>

                                    <th scope="col">Products</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Developer</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredProductList?.length > 0 && filteredProductList.map((e, i) => {

                                    return <tr className="search-items" key={i} >

                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <h6 className="fw-semibold mb-0 fs-4" >{e?.productName}</h6>
                                                    <p className="mb-0">{e?.version}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <p className="mb-0">{formatApiDate(e?.createdDate)} </p>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <span className={`${e?.status == 'AC' ? 'bg-success' : 'bg-danger'} p-1 rounded-circle`} />
                                                <p className="mb-0 ms-2">{e?.status == 'AC' ? 'Active' : 'In Active'}</p>
                                            </div>
                                        </td>
                                        <td><h6 className="mb-0 fs-4">{e?.developer}</h6></td>
                                        <td>
                                            <div className="action-btn">

                                                <a href="#" className="text-info edit ms-2" data-bs-toggle="modal" data-bs-target="#addproductModal" onClick={(event) => handleEdit(event, e?.productId)}>
                                                    <RemoveRedEyeOutlined className="fs-5" />
                                                </a>
                                                <a href="#" className="text-dark delete ms-2" onClick={() => handle_Delete(e?.productId, e?.status == 'AC' ? 'AC' : 'InActive')}>
                                                    <Delete className="fs-5" />
                                                </a>
                                            </div></td>
                                    </tr>
                                })}



                            </tbody>
                        </table>
                        <div className="d-flex align-items-center justify-content-end py-1">
                            <p className="mb-0 fs-2">
                                Rows per page:
                            </p>
                            <select
                                className="form-select w-auto ms-0 ms-sm-2 me-8 me-sm-4 py-1 pe-7 ps-2 border-0"
                                aria-label="Items per page"
                                onChange={(e) => setPageNumber(e.target.value)}
                            >
                                <option selected>5</option>
                                <option value="10">10</option>
                                <option value="25">25</option>
                            </select>
                            <p className="mb-0 fs-2">
                                {currentPage * itemsPerPage + 1} -{" "}
                                {Math.min((currentPage + 1) * itemsPerPage, getProductList.length)} of{" "}
                                {getProductList.length}
                            </p>
                            <nav aria-label="...">
                                <ul className="pagination justify-content-center mb-0 ms-8 ms-sm-9">
                                    <li className="page-item p-1">
                                        <a
                                            className="page-link border-0 rounded-circle text-dark fs-6 round-32 d-flex align-items-center justify-content-center"
                                            href="#"
                                            onClick={() => {
                                                if (currentPage > 0) {
                                                    handlePageChange(currentPage - 1);
                                                }
                                            }}
                                        >
                                            <ArrowLeft />
                                            {/* <i className="ti ti-chevron-left" /> */}
                                        </a>
                                    </li>
                                    <li className="page-item p-1">
                                        <a
                                            className="page-link border-0 rounded-circle text-dark fs-6 round-32 d-flex align-items-center justify-content-center"
                                            href="#"
                                            onClick={() => {
                                                if (currentPage < Math.ceil(getProductList.length / itemsPerPage) - 1) {
                                                    handlePageChange(currentPage + 1);
                                                }
                                            }}
                                        >
                                            {/* <i className="ti ti-chevron-right" /> */}
                                            <ArrowRight />
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <ModalPopup DataModal={<AddUpdateProducts productId={productId} productEditList={productEditList} />} id={"addproductModal"} Title={"Products"} size={'products'} form={"addProductModalTitle"} />
        </>


    );
}
export default ProductList;