import Logo from "../../assets/images/logos/vaanamwhiteLogo.png";
import Background from "../../assets/images/backgrounds/login-security.svg";

const ForgotPassword = () => {
    
    return (

        <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed">
            <div className="position-relative overflow-hidden radial-gradient min-vh-100">
                <div className="position-relative z-index-5">
                    <div className="row">
                        <div className="col-lg-6 col-xl-7 col-xxl-7">
                            <a href="#" target="_blank" className="text-nowrap logo-img d-block px-4 py-9 w-100">
                                <img src={Logo} alt="" />
                            </a>
                            <div className="d-none d-lg-flex align-items-center justify-content-center" style={{ height: 'calc(100vh - 100px)' }}>
                                <img src={Background} alt="" className="img-fluid" width={500} />
                            </div>
                        </div>
                        <div className="col-xl-5 col-xxl-5">
                            <div className="authentication-login min-vh-100 bg-body row justify-content-center align-items-center p-4">
                                <div className="col-sm-8 col-md-6 col-xl-9">
                                    <h2 className="mb-3 fs-7 fw-bolder">Forgot Password</h2>
                                    <form>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Email Address</label>
                                            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                        </div>


                                        <a href="#" className="btn btn-primary w-100 py-8 mb-3">Forgot Password</a>
                                        <a href="/" className="btn btn-light-primary text-primary w-100 py-8">Back to Login</a>                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ForgotPassword;