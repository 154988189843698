import React from "react";
import AxiosServices from "./AxiosService";

const Auth = new AxiosServices();


class AssociateService {

     getData = async () => {
          return Auth.get('/Authentication/GetEmployeeList')
     };
     Create = async (EmpID) => {
          return Auth.get(`/Authentication/Create?id=${EmpID}`)
     };

     Register = async (data) => {

          return Auth.post(`/Authentication/UserRegisteration`, data)
     };
     DeleteEmployee = async (EmpID) => {
          return Auth.get(`/Authentication/DeleteEmployee?Id=${EmpID}`)
     };

}

export default AssociateService

