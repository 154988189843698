import AxiosService from "./AxiosService";

const axios = new AxiosService();

export default class AuthenticationService {
    Login = async (data) => {
        return await axios.post("Authentication/ValidateUserLogin",data)
        // .then((response) => {
        //     return response;
        // }).catch((err) => {
        //     console.log(err);
        // });
    }
}