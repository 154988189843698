import * as signalR from "@microsoft/signalr";

class SignalRService {
    constructor() {
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl("/progressHub") // Specify the URL mapped to your SignalR hub
            .build();
    }

    startConnection = () => {
        this.connection
            .start()
            .then(() => console.log("SignalR Connected"))
            .catch((err) => console.error("SignalR Connection Error: " + err));
    };

    addProgressUpdateListener = (callback) => {

        this.connection.on("uploadProgress", (percentage) => {
            callback(percentage);
        });
    };

    stopConnection() {
        this.connection.stop().then(() => {
            console.log("SignalR Connection Stopped");
        });
    };
}

export default SignalRService;
