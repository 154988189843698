import React from "react";
import AxiosServices from "./AxiosService";

const Auth = new AxiosServices();


class PurhcaseService {

    GetPurchaseList = async () => {
        return Auth.get('/PurchaseLicenece/GetPurchaseList')
    };
    GetPurchaseById = async (PurchaseId) => {
        return Auth.get(`/PurchaseLicenece/GetPurchaseById?PurchaseId=${PurchaseId !== 0 ? PurchaseId : 0}`)
    };
    PurchaseSave = async (data) => {
        return Auth.post(`/PurchaseLicenece/PurchaseSave`, data)
    };
    DeletePurchase = async (PurchaseId) => {
        return Auth.get(`/PurchaseLicenece/DeletePurchase?PurchaseId=${PurchaseId}`)
    };
    VersionListByProductID = async (id) => {
        return await Auth.get(`PurchaseLicenece/VersionListByProductID?ProductId=${id}`).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }
    getProductsList = async () => {
        return await Auth.get("PurchaseLicenece/Products_GetList").then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }
    getCustomerList = async () => {
        return await Auth.get("PurchaseLicenece/CustomersgetList").then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    sendEmail = async (data) => {
        return await Auth.post(`/PurchaseLicenece/SendEmail`, data)
    }
    LicenseKey_Create = async () => {
        return await Auth.get(`/PurchaseLicenece/LicenseKey_Create`).then((response) => {
            return response;
        }).catch((err) => {
            console.log(err);
        })
    }

    ProductAgainst_Package = async (ProductId) => {
        try {
            return await Auth.get(`Package/ProductAgainst_Package?ProductId=${ProductId}`);
        } catch (error) {
            console.log(error);
        }
    }
}

export default PurhcaseService

