import AxiosService from "./AxiosService";

const axios = new AxiosService();

export default class PackageService {
    Packages_InsertUpdate = async (data) => {
        try {
            return await axios.post("Package/Packages_InsertUpdate", data);
        } catch (error) {
            console.log(error);
        }
    }

    Packages_GetList = async () => {
        try {
            return await axios.get(`Package/Packages_GetList`);
        } catch (error) {
            console.log(error);
        }
    }

    Package_getListById = async (PackageID) => {
        try {
            return await axios.get(`Package/Package_getListById?PackageID=${PackageID}`);
        } catch (error) {
            console.log(error);
        }
    }

    Exam_save = async (data) => {
        try {
            return await axios.post(`https://api.max-e.in/api/License/License_Details_SaveUpdate`, data);
            
        } catch (error) {
            console.log(error);
        }
    }
    Inactive_Customer = async (id) => {
        try {
            return await axios.get(`https://api.max-e.in/api/License/CompanyDelete?CustomerID=${id}`);
        } catch (error) {
            console.log(error);
        }
    }

    Packages_Delete=async(id)=>{
        try {
            return await axios.get(`Package/Packages_Delete?PackageID=${id}`);
        } catch (error) {
            console.log(error);
        }
    }

}