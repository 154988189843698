import { useEffect, useState } from "react";
import Customers_Services from "../Api/Customers_Services";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { error } from "jquery";

const customerObj = new Customers_Services();
const AddUpdateCustomer = ({ getCustomerID }) => {

    const [getCustomer, setCustomer] = useState({
        customerName: "",
        companyName: "",
        email: "",
        contactNumber: "",
        alternateNumber: "",
        address: "",
    });

    const [Error, setError] = useState({
        customerName: "",
        companyName: "",
        email: "",
        contactNumber: "",
        alternateNumber: "",
        address: "",
    });
    useEffect(() => {

        if (getCustomerID > 0) {

            Customer_getListById();
        }
    }, [getCustomerID]);

    const Customer_getListById = async () => {

        await customerObj.getCustomerListByID(getCustomerID).then((res) => {
            if (res != null) {

                setCustomer(res);

                console.log(getCustomer);
            }
        }).catch((err) => {
            console.log(err);
        });
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "contactNumber" || name === "alternateNumber") {
            const numericValue = value.replace(/[^0-9]/g, "");
            console.log(numericValue);
            if (numericValue === "") {
                setError((prevErrors) => ({
                    ...prevErrors,
                    [name]: "Enter numbers only",
                }));
            } else {
                console.log('else');
                setError((prevErrors) => ({
                    ...prevErrors,
                    [name]: "",
                }));
            }

            setCustomer((prevState) => ({
                ...prevState,
                [name]: numericValue,
            }));
        }
        else {
            setCustomer((prevState) => ({
                ...prevState,
                [name]: value,
            }));

            setError((prevErrors) => ({
                ...prevErrors,
                [name]: "",
            }));
        }

    }
    const isValidEmail = (email) => {
        // Email validation logic
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const validateForm = (e) => {
        const { name, value } = e.target;
        let valid = true;
        const errors = {};
        if (name == 'email') {
            if (!getCustomer.email) {
                errors.email = "Email is required";
                valid = false;
            } else if (!isValidEmail(getCustomer.email)) {
                errors.email = "Invalid Email Address";
                valid = false;
            } else {
                errors.email = "";
            }
        }
        else if (name == 'customerName') {
            if (!getCustomer.customerName) {
                errors.customerName = "CustomerName is required";
                valid = false;
            } else {
                errors.customerName = "";
            }
        }
        else if (name == 'companyName') {
            if (!getCustomer.companyName) {
                errors.companyName = "CompanyName is required";
                valid = false;
            } else {
                errors.companyName = "";
            }
        }

        setError((prevErrors) => ({
            ...prevErrors,
            ...errors,
        }));
        return valid;
    };


    const submitForm = () => {


        let valid = true;
        const errors = {};

        if (!getCustomer.customerName) {
            errors.customerName = "Customer Name is required";
            valid = false;
        }

        if (!getCustomer.companyName) {
            errors.companyName = "Company Name is required";
            valid = false;
        }
        if (!getCustomer.email) {

            errors.email = "Email is required";
            valid = false;
        }
        if (getCustomer.email != "") {
            if (!isValidEmail(getCustomer.email)) {
                errors.email = "Invalid Email Address";
                valid = false;
            }
        }
        if (!getCustomer.contactNumber) {

            errors.contactNumber = "Phone is required";
            valid = false;
        }
        if (getCustomer.contactNumber != "") {

            if (getCustomer.contactNumber.length < 10) {
                errors.contactNumber = "Must be enter 10-digits";
                valid = false;
            }

        }
        setError(errors);
        return valid;
    };
    const AddUpdateCustomer = async (e) => {
        e.preventDefault();
        
        const a = document.getElementById('spin');
        const formValid = submitForm();

        if (formValid) {

            const data = {
                customerId: getCustomerID > 0 ? getCustomerID : 0,
                customerName: getCustomer?.customerName,
                companyName: getCustomer?.companyName,
                email: getCustomer?.email,
                contactNumber: getCustomer?.contactNumber,
                alternateNumber: getCustomer?.alternateNumber == "" ? null : getCustomer?.alternateNumber,
                address: getCustomer?.address == "" ? null : getCustomer?.address,
                status: true,
                createdBy: 1,
            }
            a.style.display = "block";
            await customerObj.Customers_AddUpdate(data).then((res) => {
                if (res != null) {
                    a.style.display = "none"

                    if (getCustomerID > 0) {

                        toast.success(
                            'Customer updated Succesfully',
                            {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            }
                        );
                    } else {
                        if (res == 0) {
                            toast.error(
                                'Email or Mobile Number Already Exists',
                                {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 2000,
                                }
                            );
                            return;
                        } else {
                            toast.success(
                                'Customer Created Succesfully',
                                {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 2000,
                                }
                            );
                        }
                    }

                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                }
            }).catch((err) => {
                a.style.display = "none"

                console.log(err);
            });
        }
        else {
            a.style.display = 'none';
            console.log('Is not valid');
        }
    }

    const close = (e) => {

        e.preventDefault();
        window.location.reload();
    }
    return (
        <>

            <form id="addContactModalTitle" autoComplete="off" onSubmit={AddUpdateCustomer}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3 contact-name">
                            <label class="mr-sm-2" for="inlineFormCustomSelect">Customer Name <span className='text-danger'>*</span></label>

                            <input type="text" id="customerName" name="customerName" className="form-control" value={getCustomer.customerName} onChange={handleInputChange} disabled={getCustomerID != 0 ? true : false} />

                            {Error.customerName && <span className="validation-text text-danger">{Error.customerName}</span>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3 contact-name">
                            <label class="mr-sm-2" for="inlineFormCustomSelect">Company Name <span className='text-danger'>*</span></label>

                            <input type="text" id="companyName" name="companyName" className="form-control" value={getCustomer.companyName} onChange={handleInputChange} disabled={getCustomerID != 0 ? true : false} />
                            {Error.companyName && <span className="validation-text text-danger">{Error.companyName}</span>}
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3 contact-email">
                            <label class="mr-sm-2" for="inlineFormCustomSelect">Email <span className='text-danger'>*</span></label>

                            <input type="text" id="email" name="email" className="form-control" value={getCustomer.email} onChange={handleInputChange} onBlur={(e) => validateForm(e)} disabled={getCustomerID != 0 ? true : false} />
                            {Error.email && <span className="validation-text text-danger">{Error.email}</span>}

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3 contact-phone">
                            <label class="mr-sm-2" for="inlineFormCustomSelect">Phone <span className='text-danger'>*</span></label>

                            <input type="text" id="customerPhone" name="contactNumber" className="form-control" maxLength={10} value={getCustomer.contactNumber} onChange={handleInputChange} disabled={getCustomerID != 0 ? true : false} />
                            {Error.contactNumber && <span className="validation-text text-danger">{Error.contactNumber}</span>}
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3 contact-occupation">
                            <label class="mr-sm-2" for="inlineFormCustomSelect">Alternate Phone<span className='text-danger'></span></label>

                            <input type="text" id="customerAlternate" name="alternateNumber" className="form-control" maxLength={10} value={getCustomer.alternateNumber} onChange={handleInputChange} />
                            {Error.alternateNumber && <span className="validation-text text-danger">{Error.alternateNumber}</span>}

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3 contact-location">
                            <label class="mr-sm-2" for="inlineFormCustomSelect">Location<span className='text-danger'></span></label>

                            <input type="text" id="location" name="address" className="form-control" value={getCustomer.address} onChange={handleInputChange} placeholder="Location" />
                        </div>
                    </div>
                </div>
                <div className="text-end">
                    <button id="btn-edit" type="submit" className="btn btn-success rounded-pill px-4">Save</button>
                    <button className="btn btn-danger rounded-pill px-4 ms-2" data-bs-dismiss="modal" onClick={close}> Discard </button>
                </div>


            </form>
            <ToastContainer />
        </>
    )
}
export default AddUpdateCustomer;