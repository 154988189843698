import { useLocation, useNavigate } from "react-router-dom";
import Product_Services from "./Api/Products_Services";
import { useEffect, useState, version } from "react";
import { ArrowLeft, ArrowRight, CloudDownload, RemoveRedEyeOutlined, Search } from "@mui/icons-material";
import { Encrypt } from "./Api/CommonService";

const Versions = () => {

    const location = useLocation();
    const productId = location.state?.productId ?? 0;
    if (productId != 0) {
        localStorage.setItem('productid', productId);
    }

    const PObj = new Product_Services();
    const [versionList, setversionList] = useState([]);
    const [productName, setProductName] = useState('');
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(0); // Current page
    const [pageNumber, setPageNumber] = useState(5);
    const itemsPerPage = parseInt(pageNumber); // Number
    const [searchQuery, setSearchQuery] = useState("");
    const [deviceType, setDeviceType] = useState(false);

    useEffect(() => {
        get_versionList()
    }, [productId]);

    const get_versionList = async () => {

        const id = localStorage.getItem('productid');
        localStorage.removeItem('versionList');
        const res = await PObj.VersionListByProductID(id != null ? parseInt(id) : productId);
        if (res != null && res != "") {


            const data = Encrypt(res);
            localStorage.setItem('versionList', data);
            setProductName(res[0]?.productName);
            setversionList(res);
            const check_Device = res.some((e) => e.deviceType === "Web Application")
            setDeviceType(check_Device);
        }
    }

    const HandleNavigate = (id, pId) => {
        if (deviceType == true) {
            localStorage.setItem('productversionId', id);
            navigate('/Web', { state: { productId: id } });
        }
        else {
            localStorage.setItem('productversionId', id);
            navigate('/ProductsServe', { state: { productId: id } });
        }

    }

    const formatDate = (inputDate) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(inputDate);
        return date.toLocaleDateString('en-US', options);
    };
    const getItemsForCurrentPage = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return versionList.slice(startIndex, endIndex);
    };
    const itemsToDisplay = getItemsForCurrentPage();
    const filteredVersionListList = itemsToDisplay.filter((product) =>
        product?.version.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };
    return (
        <>
            <div className="card bg-light-info shadow-none position-relative overflow-hidden">
                <div className="card-body px-4 py-3">
                    <div className="row align-items-center">
                        <div className="col-9">
                            <h4 className="fw-semibold mb-8">{productName != null && productName != 'null' ? productName : ""}</h4>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a className="text-muted " href="/home">Home</a></li>
                                    <li className="breadcrumb-item" aria-current="page">{productName != null && productName != 'null' ? productName : ""}</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-3">
                            <div className="text-center mb-n5">
                                <img alt="" className="img-fluid mb-n4" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="widget-content searchable-container list">
                {/* --------------------- start Contact ---------------- */}
                <div className="card card-body">
                    <div className="row">
                        <div className="col-md-4 col-xl-3">
                            <form className="position-relative">
                                <input type="text" className="form-control product-search ps-5" id="input-search" placeholder="Search Versions..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                <Search className="position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3" />
                            </form>
                        </div>

                    </div>
                </div>
                {/* ---------------------
                              end Contact
                          ---------------- */}
                {/* Modal */}

                <div className="card card-body">
                    <div className="table-responsive">
                        <table className="table search-table align-middle text-nowrap">
                            <thead className="header-item">
                                <tr>

                                    <th>Version</th>
                                    <th>Release Date</th>
                                    {deviceType ? <th>WebApplication URL</th> : <th>Exe File</th>}
                                    <th>Action</th>
                                </tr></thead>
                            <tbody>
                                {/* start row */}
                                {filteredVersionListList?.length > 0 && filteredVersionListList.map((e, i) => {

                                    return <tr className="search-items" key={i}>
                                        <td>
                                            <span>{e?.version}</span>
                                        </td>
                                        <td>
                                            <span>{formatDate(e?.createdDate)}</span>
                                        </td>
                                        {deviceType ? <td>
                                            <a href={e?.webApplicationURl} target="_blank">{e?.webApplicationURl}</a>
                                        </td> : <td>
                                            <a href={e?.versionExePath} target="_blank"><CloudDownload /></a>
                                        </td>}

                                        <td>
                                            <div className="action-btn ms-2">
                                                <a className="text-info edit" onClick={() => HandleNavigate(e?.productVersionId, e?.productId)}>
                                                    <RemoveRedEyeOutlined className="fs-5" />

                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                })}
                                {/* end row */}

                                {/* end row */}
                            </tbody>
                        </table>
                        <div className="d-flex align-items-center justify-content-end py-1">
                            <p className="mb-0 fs-2">Rows per page:</p>
                            <select
                                className="form-select w-auto ms-0 ms-sm-2 me-8 me-sm-4 py-1 pe-7 ps-2 border-0"
                                aria-label="Items per page"
                                onChange={(e) =>
                                    setPageNumber(e.target.value)
                                    // You can update the itemsPerPage state here
                                }
                            >
                                <option value="5" selected>5</option>
                                <option value="10">10</option>
                                <option value="25">25</option>
                            </select>
                            <p className="mb-0 fs-2">
                                {currentPage * itemsPerPage + 1} -{" "}
                                {Math.min((currentPage + 1) * itemsPerPage, versionList.length)} of{" "}
                                {versionList.length}
                            </p>
                            <nav aria-label="...">
                                <ul className="pagination justify-content-center mb-0 ms-8 ms-sm-9">
                                    <li className="page-item p-1">
                                        <a
                                            className="page-link border-0 rounded-circle text-dark fs-6 round-32 d-flex align-items-center justify-content-center"
                                            href="#"
                                            onClick={() => {
                                                if (currentPage > 0) {
                                                    handlePageChange(currentPage - 1);
                                                }
                                            }}
                                        >
                                            <ArrowLeft />
                                        </a>
                                    </li>
                                    <li className="page-item p-1">
                                        <a
                                            className="page-link border-0 rounded-circle text-dark fs-6 round-32 d-flex align-items-center justify-content-center"
                                            href="#"
                                            onClick={() => {
                                                if (currentPage < Math.ceil(versionList.length / itemsPerPage) - 1) {
                                                    handlePageChange(currentPage + 1);
                                                }
                                            }}
                                        >
                                            <ArrowRight />
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
export default Versions;