
const ModalPopup = ({ DataModal, id, Title, size, form }) => {
    console.log(form);

    const close = (e) => {

        e.preventDefault();
        window.location.reload();


    }
    return (
        <>
            <div className="modal fade" id={id} tabIndex={-1} role="dialog" aria-labelledby="addContactModalTitle" aria-hidden="true">
                <div className={size === "products" ? "modal-dialog modal-xl" :size ==="Orders"?"modal-dialog modal-dialog-centered modal-lg":"modal-dialog modal-dialog-centered"} role="document">
                    <div className="modal-content">
                        <div className="modal-header d-flex align-items-center">
                            <h5 className="modal-title">{Title}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={close} />
                        </div>
                        <div className="modal-body">
                            <div className="add-contact-box">
                                <div className="add-contact-content">
                                    {DataModal}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ModalPopup;