import { createContext, useState,useContext } from "react";
import { Cookies, useCookies } from "react-cookie";
import { Encrypt, Decrypt } from "./CommonService";
import { COOKIES } from "./Global";
import { GetNumberOfDaysInSec } from "./CommonService";


export const AppContext = createContext({
  getCookies: (name) => {},
  setCookies: (name, data) => {},
  removeCookies: (name) => {},
  loadingStatus: false,
  showLoading: () => {},
  hideLoading: () => {},
  currentMasterTab: 0,
  handleMasterTab: (tabNo) => {},
  PeriodicEditData: {},
  handlePeriodicEditData: (data) => {},
  refreshCal: false,
  handleCal: () => {},
  calRefresh: false,
  handleCalRefresh: () => {},
  isAuth: true,
  SetisAuth:(name)=>{}
});

export const AppContextProvider = ({ children }) => {
  {
    /* Cookies */
  }
  const [cookies, setCookies, removeCookie] = useCookies([
    COOKIES.Jwt,
    COOKIES.PeriodicStationID,
    COOKIES.modulePrivileage,
    COOKIES.EmployeeID,
  ]);

  const GetCookies = (name) => {
    var value = cookies[`${name}`];

    if (value != null) {
      return value;
    } else {
      return null;
    }
  };

  const SetCookies = (name, data, expiryDate = GetNumberOfDaysInSec(1)) => {
    // var encrptedData = Encrypt(data);
    setCookies(name, data, { path: "/", maxAge: expiryDate });
  };

  const RemoveCookies = (name) => removeCookie(name);

  {
    /* For Loader */
  }
  const [showLoading, setShowLoading] = useState(false);

  const handleShowLoading = () => setShowLoading(true);
  const handleHideLoading = () => setShowLoading(false);

  {
    /* Master tab */
  }
  const [masterTab, setMasterTab] = useState(0);

  const handleMasterTabs = (tabNo) => {
    setCookies('ID', tabNo);
    setMasterTab(tabNo);
  }
  {
    /* Periodic Edit data */
  }
  const [editData, setEditData] = useState({});

  const handleEditData = (data) => setEditData(data);

  {
    /* Refresh Calendar */
  }
  const [isAuth,SetisAuth] =useState(false);
  const [refreshCal, setRefreshCal] = useState(false);

  const handleCal = () => setRefreshCal(!refreshCal);

  const [calRefresh, setCalRefresh] = useState(false);

  const handleCalRefresh = () => setCalRefresh(!calRefresh);



  const contextValue = {
    getCookies: GetCookies,
    setCookies: SetCookies,
    removeCookies: RemoveCookies,
    loadingStatus: showLoading,
    showLoading: handleShowLoading,
    hideLoading: handleHideLoading,
    currentMasterTab: masterTab,
    handleMasterTab: handleMasterTabs,
    PeriodicEditData: editData,
    handlePeriodicEditData: handleEditData,
    refreshCal: refreshCal,
    handleCal: handleCal,
    cookies: cookies,
    calRefresh: calRefresh,
    handleCalRefresh: handleCalRefresh,
    isAuth: isAuth,
    SetisAuth:SetisAuth
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
