import Axios from "axios";
import { BASE_URL } from "../../env";


//Set base url
Axios.defaults.baseURL = BASE_URL;
// require("dotenv").config();


//Set authorization

// Axios.defaults.headers.common["Authorization"] =
//   "Bearer " + Decrypt(localStorage.getItem(Global.COOKIES.Jwt));

export default class AxiosService {
    post(url, data, header = null) {
        return Axios.post(url, data, header);
    }

    get(url, header = null) {
        return Axios.get(url, header);
    }
}
