import AxiosService from "./AxiosService";
const axios = new AxiosService();
export default class Product_Services {
    AddUpdate_Products = async (data) => {
        return await axios.post("Product/AddUpdate_Products", data
        ).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    getProductsList = async () => {
        return await axios.get("Product/Products_GetList").then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    ProductsGetListById = async (id) => {
        return await axios.get(`Product/Licensing_ProductsGetListById?id=${id}`).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    VersionListByProductID = async (id) => {
        return await axios.get(`Product/VersionListByProductID?id=${id}`).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    Delete_Products = async (id, Parameter) => {
        return await axios.post(`Product/Delete_Products?ProductId=${id}&Parameter=${Parameter}`).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }
    Licensing_ProductsVersionDelete = async (id) => {
        return await axios.post(`Product/Licensing_ProductsVersionDelete?id=${id}`).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }
}