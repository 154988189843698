import Logo from '../assets/images/logos/vaanamwhiteLogo.png';
import Header from './Header';
import ShoppingCart from './ShoppingCart';
import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import miniSidebar from '../assets/images/logos/vaanamwhiteLogo.png';
import Loader from './Loader';
import { Category, Dashboard, Details, Home, ListAlt, Person2, ProductionQuantityLimits, ProductionQuantityLimitsTwoTone, Settings, Shop, ShopTwoSharp, ShoppingCartCheckout, ShowChart, VerifiedUserTwoTone } from '@mui/icons-material';


const SideBar = () => {
    const [sidebartypeValue, setSidebartypeValue] = useState(null);

    // Function to handle changes to the data-sidebartype attribute
    function handleAttributeChange(mutationsList) {

        for (let mutation of mutationsList) {
            if (mutation.type === "attributes" && mutation.attributeName === "data-sidebartype") {
                // Get the new value of the attribute
                const newSidebartypeValue = mutation.target.getAttribute("data-sidebartype");
                if (newSidebartypeValue) {
                    setSidebartypeValue(newSidebartypeValue);
                }
            }
        }
    }

    useEffect(() => {
        // Get the element by its ID
        const mainWrapper = document.getElementById("main-wrapper");

        // Check if the element exists
        if (mainWrapper) {
            // Create a new MutationObserver
            const observer = new MutationObserver(handleAttributeChange);

            // Start observing changes to the data-sidebartype attribute
            observer.observe(mainWrapper, { attributes: true, attributeFilter: ["data-sidebartype"] });

            // Initial value
            const initialSidebartypeValue = mainWrapper.getAttribute("data-sidebartype");
            if (initialSidebartypeValue) {
                setSidebartypeValue(initialSidebartypeValue);
            }
        } else {
            console.log("Element with ID 'main-wrapper' not found.");
        }
    }, []);

    return (
        <>
            <div>
                <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed">

                    <Loader />
                    {/* Sidebar Start */}
                    < aside className="left-sidebar" >
                        {/* Sidebar scroll*/}
                        < div >
                            <div className="brand-logo d-flex align-items-center justify-content-between">
                                {sidebartypeValue == "mini-sidebar" ?
                                    <a href="/home" target="_blank" className="text-nowrap logo-img">
                                        <img src={miniSidebar} alt="" />
                                    </a>
                                    :

                                    <a href="/home" target="_blank" className="text-nowrap logo-img">
                                        <img src={Logo} alt="" />
                                    </a>
                                }
                                {/* <a href="/home" target="_blank" className="text-nowrap logo-img">
                                    <img src={Logo} alt="" />
                                </a> */}
                                <div className="close-btn d-lg-none d-block sidebartoggler cursor-pointer" id="sidebarCollapse">
                                    <i className="ti ti-x fs-8 text-muted" />
                                </div>
                            </div>
                            {/* Sidebar navigation*/}
                            <nav className="sidebar-nav scroll-sidebar" data-simplebar>
                                <ul id="sidebarnav">
                                    {/* ============================= */}
                                    {/* Home */}
                                    {/* ============================= */}
                                    <li className="nav-small-cap">
                                        <i className="ti ti-dots nav-small-cap-icon fs-4" />
                                        <span className="hide-menu">DashBoard</span>
                                    </li>
                                    {/* =================== */}
                                    {/* Dashboard */}
                                    {/* =================== */}
                                    <li className="sidebar-item">
                                        <a className="sidebar-link" href="/home" aria-expanded="false">
                                            <span>
                                                {/* <i className="ti ti-home" /> */}
                                                <Home />
                                            </span>
                                            <span className="hide-menu">Home</span>
                                        </a>
                                    </li>
                                    <li className="sidebar-item">
                                        <a className="sidebar-link" href="/dashboard" aria-expanded="false">
                                            <span>
                                                {/* <i className="ti ti-aperture" /> */}
                                                <Dashboard />
                                            </span>
                                            <span className="hide-menu">Dashboard</span>
                                        </a>
                                    </li>
                                    <li className="nav-small-cap">
                                        <i className="ti ti-dots nav-small-cap-icon fs-4" />
                                        <span className="hide-menu">Details</span>
                                    </li>
                                    <li className="sidebar-item">
                                        <a className="sidebar-link" href="/customerList" aria-expanded="false">
                                            <span>
                                                {/* <i className="ti ti-file-text" /> */}
                                                <ListAlt />
                                            </span>
                                            <span className="hide-menu">Customers List</span>
                                        </a>
                                    </li>
                                    <li className="sidebar-item">
                                        <a className="sidebar-link" href="/productList" aria-expanded="false">
                                            <span>
                                                {/* <i className="ti ti-list-details" /> */}
                                                <ShoppingCartCheckout />
                                            </span>
                                            <span className="hide-menu">Products List</span>
                                        </a>
                                    </li>
                                    <li className="sidebar-item">
                                        <a className="sidebar-link" href="/PurchaseGrid" aria-expanded="false">
                                            <span>
                                                {/* <i className="ti ti-cards" /> */}
                                                <Shop />
                                            </span>
                                            <span className="hide-menu">Orders List</span>
                                        </a>
                                    </li>
                                    <li className="sidebar-item">
                                        <a className="sidebar-link" href="/Associate" aria-expanded="false">
                                            <span>
                                                <Person2 />
                                            </span>
                                            <span className="hide-menu">Associates</span>
                                        </a>
                                    </li>

                                    <li className="sidebar-item">
                                        <a className="sidebar-link" href="/configure" aria-expanded="false">
                                            <span>
                                                <Settings />
                                            </span>
                                            <span className="hide-menu">Configuration</span>
                                        </a>
                                    </li>
                                </ul>
                                {/* <div className="unlimited-access hide-menu bg-light-primary position-relative my-7 rounded">
                                    <div className="d-flex">
                                        <div className="unlimited-access-title">
                                            <h6 className="fw-semibold fs-4 mb-6 text-dark w-85">Unlimited Access</h6>
                                            <button className="btn btn-primary fs-2 fw-semibold lh-sm">Signup</button>
                                        </div>
                                        <div className="unlimited-access-img">
                                            <img src="images/rocket.png" alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                </div> */}
                            </nav>
                            {/* <div className="fixed-profile p-3 bg-light-secondary rounded sidebar-ad mt-3">
                                <div className="hstack gap-3">
                                    <div className="john-img">
                                        <img src="images/profile/user-1.jpg" className="rounded-circle" width={40} height={40} alt="" />
                                    </div>
                                    <div className="john-title">
                                        <h6 className="mb-0 fs-4 fw-semibold">Mathew</h6>
                                        <span className="fs-2 text-dark">Designer</span>
                                    </div>
                                    <button className="border-0 bg-transparent text-primary ms-auto" tabIndex={0} type="button" aria-label="logout" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="logout">
                                        <i className="ti ti-power fs-6" />
                                    </button>
                                </div>
                            </div> */}
                            {/* End Sidebar navigation */}
                        </div >
                        {/* End Sidebar scroll*/}
                    </aside >
                    {/* Sidebar End */}
                    <div className="body-wrapper">
                        <Header />
                        <div className="container-fluid">
                            <Outlet />

                        </div>
                    </div>
                    {/* <div className="dark-transparent sidebartoggler" /> */}
                </div>
                <ShoppingCart />

            </div>
        </>
    )
}
export default SideBar;