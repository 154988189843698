import React, { useState, useEffect, useContext, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import MUIDataTable from "mui-datatables";
import "react-tabs/style/react-tabs.css";
import PurhcaseService from "../Api/PurchaseService";
import Select from 'react-select';
import PackageService from '../Api/PackageService';
import { ToastContainer,toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Delete, Edit } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import Swal from "sweetalert2";
export default function Configuration() {
    const [ProductList, setProductList] = useState([]);
    const [List, setList] = useState([]);
    const [data, setData] = useState([]);
    const [selectProduct, setSelectProduct] = useState(null);
    const [showCount, setShowCount] = useState(false);
    const [showTable, setShowTable] = useState(false)
    const [errors, setErrors] = useState({});
    const packageName = useRef(null);
    const [packName, setpackName] = useState('');
    const periodMonth = useRef(null);
    const [periMonth, setperiMonth] = useState('');
    const examCount = useRef(null);
    const [Ecount, setEcount] = useState('');
    const [selectedTab, setSelectedTab] = useState(0);
    const [packageId, setpackageID] = useState(0);
    const [productId, setproductID] = useState(0);
    const PurhcaseServiceObj = new PurhcaseService();
    const PackObj = new PackageService();

    useEffect(() => {
        get_ProductList()
        getList()
    }, [])

    const Edit_Configuration = async (id) => {

        const a = document.getElementById('spin');
        a.style.display = "block";
        const res = await PackObj.Package_getListById(id);
        if (res.data != null) {

            const filterData = List && List?.filter((e) => e.productId == res?.data?.productId);
            const filterArray = filterData && filterData?.map((e) => ({ label: e?.productName, value: e?.productId }));
            setSelectProduct(filterArray);
            setpackageID(res?.data?.packageId);
            setproductID(res?.data?.productId);
            
            if(filterData[0]?.deviceType=="Web Application"){
                let PackageNameDummy=res?.data?.packageName
                let PackageAmmountWithGST=PackageNameDummy?.split("-")?.[1]?.split("(")?.[0]
            if(PackageAmmountWithGST!=null && PackageAmmountWithGST!=undefined && !isNaN(PackageAmmountWithGST))
            {
                let GST=18
                let PackageAmmountNonGST=Math.round((PackageAmmountWithGST /(1 + GST / 100)))
                PackageNameDummy = (PackageNameDummy?.replace(PackageAmmountWithGST, PackageAmmountNonGST + " "))?.trim()?.split("(")[0];
                setpackName(PackageNameDummy);
            }
        }
        else{
            setpackName(res?.data?.packageName)
        }
           
            setperiMonth(res?.data?.periodMonth);
            const check_product = List.some((e) => e?.productId == res?.data?.productId && e?.deviceType == 'Web Application');
            if (check_product) {
                setShowCount(true);
                setEcount(res?.data?.examCount);

            } else {
                setShowCount(false);
            }
            a.style.display = "none";
            console.log(res.data);
            window.scrollTo(0, 0);
            packageName.current.focus();
            packageName.current.style.borderColor = 'yellow';

        }
    }

    const delete_config=async(id)=>{
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want delete this Package?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, ignore'
        }).then((result) => {
            if (result.isConfirmed) {
                PackObj.Packages_Delete(id).then(async (res) => {
                    if (res?.data>0) {
                        toast.success(
                            'Package Deleted Succesfully',
                            {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            }
                        );
                        setTimeout(() => {
                            window.location.reload();
                        }, 1500);
                    }
                    else {
                        toast.error(
                            'Failed to Delete',
                            {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            }
                        );
                    }
                })

            }
        })
    }
    const get_ProductList = async () => {

        let res = await PurhcaseServiceObj.getProductsList()
        if (res != null && res != "") {

            setList(res);
            const newArray = res.map((e) => ({ label: e?.productName, value: e?.productId }));
            setProductList(newArray);
        }
    }

    const getList = async () => {
        let res = await PackObj.Packages_GetList()
        if (res != null && res != "") {

            setData(res?.data);
        }
    }
    const columns = [
        { name: "productName", label: "Product Name" },
        { name: "packageName", label: "Package Name" },
        { name: "periodMonth", label: "Period Month" },
        {
            label: "OPTIONS",
            name: "Options",

            options: {
                filter: false,
                sort: false,
                empty: true,

                responsive: true,

                customBodyRender: (values, tableMeta) => {

                    const packageId = data[tableMeta.rowIndex].packageId;

                    // const value = parameterAgainstValues[tableMeta.rowIndex].value;
                    return (
                        <div className="ico-sec">
                            <Tooltip title="Edit Value">
                                <IconButton
                                    type="button"
                                    title=""
                                    className="text-primary"
                                    data-original-title="Edit"

                                    // style={{ outlineColor: "green" }}
                                    onClick={() => Edit_Configuration(packageId)}
                                >
                                    <Edit className="edit-icon" />{" "}
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete Value">
                                <IconButton
                                    className="text-danger"
                                    aria-label="Delete"
                                    onClick={() => delete_config(packageId)}
                                >
                                    <Delete />
                                </IconButton>
                            </Tooltip>

                            {/* <TrashFill   /> */}
                        </div>
                    );
                },
            },
        },
    ];

    const handleProductChange = (selectedOption) => {

        const checkExamApp = selectedOption.filter(option => List.some(item => item?.productId === option.value && item.deviceType == 'Web Application'));
        if (checkExamApp.length > 0) {
            setShowCount(true);
        }
        console.log(selectedOption);
        setSelectProduct(selectedOption); // Update the customer state with the selected option object
    };


    const package_Save = async (e) => {
        e.preventDefault();
        

        // Check if the required elements exist
        const a = document.getElementById('spin');
        if (!a) {
            console.error("Element with ID 'spin' not found");
            return;
        }
 
        // Access properties safely and use trim for string values
        const selectedproducts = selectProduct?.map(e => e?.value).join(', ');
        let productIDDummy=productId==0?selectedproducts:productId
        let deviceTypeDummy=List?.find(a=>a.productId==productIDDummy)?.deviceType
        let PackageNameDummy=null;
        if(deviceTypeDummy=="Web Application"){
            PackageNameDummy=packageName?.current?.value
            let PackageAmmount=PackageNameDummy?.split("-")?.[1].trim()
        if(PackageAmmount!=null && PackageAmmount!=undefined && !isNaN(PackageAmmount))
        {
            let GST=18
            let PackageAmmountGST=(PackageAmmount*GST)/100
            let TotalAmmount=Math.round((PackageAmmountGST+Number(PackageAmmount)))
            PackageNameDummy=packageName?.current?.value?.replace(PackageAmmount,TotalAmmount) + " (including 18% GST)"
        }
        }
        const data = {
            packageId: packageId != 0 ? packageId : 0,
            productId: productId != 0 ? productId : 0,
            productIds: selectedproducts,
            packageName: PackageNameDummy!=null && PackageNameDummy!=undefined?PackageNameDummy:packageName?.current?.value != "" && packageName?.current?.value != null ? packageName?.current?.value : null,
            packageDesc: " ",
            periodMonth: periodMonth?.current?.value.trim() ? Number(periodMonth?.current?.value) : null,
            examCount: examCount?.current?.value.trim() || null,
            status: "AC",
            createdBy: 1,
        }

        try {
            // Use await without .catch for better error handling
            await PackObj.Packages_InsertUpdate(data).then((res) => {
                a.style.display = 'none';
                
                if (res?.data > 0) {
                    toast.success(
                        'Package Saved Succesfully',
                        {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        }
 
 
                    )
                    
                    // getList();
                   
                } else {
                    console.error("Failure");
                }
                setTimeout(() => {
                    window.location.reload();

                }, 1500);
            }).catch((err) => {
                console.log(err);
            })
 
 
        } catch (error) {
            console.error("API call failed:", error);
        }
    }

    return (
        <>
            <div className="card bg-light-info shadow-none position-relative overflow-hidden">
                <div className="card-body px-4 py-3">
                    <div className="row align-items-center">
                        <div className="col-9">
                            <h4 className="fw-semibold mb-8">Configuration</h4>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a className="text-muted " href="/home">Home</a></li>
                                    <li className="breadcrumb-item" aria-current="page">Configuration</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-3">
                            <div className="text-center mb-n5">
                                <img alt="" className="img-fluid mb-n4" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Tabs>
                <TabList>
                    <Tab onClick={() => setSelectedTab(0)}><h3 style={{ fontWeight: "normal" }} className={selectedTab === 0 ? "selected-tab-content" : ""}>Application Configuration</h3></Tab>
                </TabList>


                <TabPanel>
                    <form className="row g-3" style={{ marginTop: "5px", padding: "15px" }} autoComplete="off" onSubmit={package_Save}>


                        <div className="col-md-4">
                            <label className="form-label">
                                Products List <span className="text-danger"> *</span>
                            </label>
                            <Select options={ProductList} theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#2a3447',
                                    primary: '#2a3447',
                                },
                            })}
                                value={selectProduct}
                                id="inlineFormCustomSelect"
                                className='customerList'
                                isMulti
                                onChange={handleProductChange}
                            />
                            {errors.parameter && (
                                <span className="text-danger">
                                    {errors.parameter}
                                </span>
                            )}
                        </div>

                        <div className="col-md-4">
                            <label className="form-label">
                                Packages Name <span className="text-danger"> *</span>
                            </label>

                            <input type="text" class="form-control" value={packName} ref={packageName} id="selectValues" onChange={(e) => setpackName(e.target.value)} />

                        </div>

                        <div className="col-md-4">
                            <label className="form-label">
                                Period Month <span className="text-danger"> *</span>
                            </label>
                            <input type="text" class="form-control" value={periMonth} ref={periodMonth} id="selectValues" onChange={(e) => setperiMonth(e.target.value)} />
                        </div>
                        {showCount && <div className="col-md-4">
                            <label className="form-label">
                                Exams Count <span className="text-danger"> *</span>
                            </label>
                            <input type="text" value={Ecount} class="form-control" ref={examCount} id="selectValues" onChange={(e) => setEcount(e.target.value)} />
                        </div>}



                        <div className="col-12 text-end">
                            <button type="submit" className="btn btn-primary" >
                                Save
                            </button>

                        </div>

                        <MUIDataTable

                            data={data}
                            columns={columns}
                            options={{
                                responsive: true,
                                download: false,
                                print: false,
                                filter: false,
                                rowsPerPageOptions: [10, 50, 100],
                                customToolbar: () => (
                                    <>

                                    </>
                                ),
                                selectableRows: false,

                                viewColumns: false,
                            }}
                        />




                    </form>
                </TabPanel>

            </Tabs>
            <ToastContainer />

        </>
    );
}


