import React, { useState, useContext, useEffect, useReducer } from "react";
import $ from "jquery";
import { AppContext } from "../Api/ContextProvider";

import AssociateService from "../Api/AssociateService";
// import "datatables.net";
// import 'datatables.net'
// import { Modal, Button } from "react-bootstrap";
import AddOrEditEmployee from "./AddOrEditEmployee";
import swal from "sweetalert";
import "datatables.net";
import Toast from "../../Components/Toastify";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ArrowLeft, ArrowRight, Delete, Person2Outlined, RemoveRedEyeOutlined, RemoveRedEyeRounded, Search } from "@mui/icons-material";
import Swal from "sweetalert2";

const AssociateServiceObj = new AssociateService();

const MainGrid = (props) => {
  // const {cookies} =  useContext(AppContext);
  const [EmpID, SetEmpID] = useState(0);
  const [EmployeeDatailes, SetEmployeeDatailes] = useState([]);
  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const [ModelName, setModelName] = useState("");
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);
  const [currentPage, setCurrentPage] = useState(0); // Current page
  const itemsPerPage = 5;

  // const [priviledge, setPriviledge] = useState(cookies.UserPrivileges);
  function HandleClick(id) {
    SetEmpID(id);
    if (id == 0) {
      setModelName("Employee Create");
    } else {
      setModelName("Employee Edit");
    }
    //PageRender();
  }
  const getItemsForCurrentPage = () => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return EmployeeDatailes.slice(startIndex, endIndex);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  function DeleteEmployee(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this employee?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, ignore'
    }).then((result) => {
      if (result.isConfirmed) {

        AssociateServiceObj.DeleteEmployee(id)
          .then((res) => {
            Toast("Employee deleted succesfully", "success");

            setGridRender();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }
  const [users, setUsers] = useState([]);
  useEffect(() => {
    AssociateServiceObj.getData()
      .then((res) => {
        if (res != null && res.data != null && res.data.length > 0) {
          SetEmployeeDatailes([...res.data]);
          $("#AssociateDisplay").DataTable().destroy();

          console.log(...res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // setTimeout(() => {
    //   $('#AssociateDisplay').DataTable().destroy();
    //   setUsers([]);
    // },2000);
  }, [GridRender, Render]);

  useEffect(() => {
    $("#AssociateDisplay").DataTable({
      ordering: false,
    });
  }, [EmployeeDatailes]);

  function refreshPage() {
    window.location.reload(false);
  }



  const clearForm = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  return (
    <>

      <div className="card bg-light-info shadow-none position-relative overflow-hidden">
        <div className="card-body px-4 py-3">
          <div className="row align-items-center">
            <div className="col-9">
              <h4 className="fw-semibold mb-8">Associate List</h4>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a className="text-muted " href="/home">Home</a></li>
                  <li className="breadcrumb-item" aria-current="page">Employee List</li>
                </ol>
              </nav>
            </div>
            <div className="col-3">
              <div className="text-center mb-n5">
                <img alt="" className="img-fluid mb-n4" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="widget-content searchable-container list">
        {/* --------------------- start Contact ---------------- */}
        <div className="card card-body">
          <div className="row">
            <div className="col-md-4 col-xl-3">
              <form className="position-relative">
                <input type="text" className="form-control product-search ps-5" id="input-search" placeholder="Search Associates..." />
                <Search className="position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3" />
              </form>
            </div>
            <div className="col-md-8 col-xl-9 text-end d-flex justify-content-md-end justify-content-center mt-3 mt-md-0">
              <div className="action-btn show-btn" style={{ display: 'none' }}>
                <a href="#" className="delete-multiple btn-light-danger btn me-2 text-danger d-flex align-items-center font-medium">
                  <i className="ti ti-trash text-danger me-1 fs-5" /> Delete All Row
                </a>
              </div>
              <a href="#" id="btn-add-contact" className="btn btn-info d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#addRowModal1">
                {/* <i className="ti ti-users text-white me-1 fs-5" /> */}
                <Person2Outlined className="text-white me-1 fs-5" />
                Add Associate
              </a>
            </div>
          </div>
        </div>
        {/* ---------------------
                              end Contact
                          ---------------- */}
        {/* Modal */}

        <div className="card card-body">
          <div className="table-responsive">
            <table className="table search-table align-middle text-nowrap">
              <thead className="header-item">
                <tr>

                  <th>Employee Code</th>
                  <th>Employee Name</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr></thead>
              <tbody>
                {/* start row */}
                {EmployeeDatailes?.length > 0 && EmployeeDatailes.map((e, i) => {

                  return <tr className="search-items" >

                    <td>
                      <div className="d-flex align-items-center">
                        {/* <img src={userImage1} alt="avatar" className="rounded-circle" width={35} /> */}
                        <div>
                          <div className="user-meta-info">
                            <h6 className="user-name mb-0" data-name="Emma Adams">{e.employeeCode}</h6>
                            <p className="fs-2 mb-0 text-muted">{e.employeeCode}</p>                                                </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span>{e.employeeName}</span>
                    </td>
                    <td>
                      <span>{e.email}</span>
                    </td>
                    <td>
                      <span>{e.isActive}</span>
                    </td>
                    <td>
                      <div className="action-btn">

                        <a href="#" className="text-info edit ms-2" data-bs-toggle="modal" data-bs-target="#addRowModal1" onClick={() => HandleClick(e.employeeId)}>
                          {/* <i className="ti ti-eye fs-5" /> */}
                          <RemoveRedEyeOutlined className="fs-5" />
                        </a>
                        <a href="#" className="text-dark delete ms-2" onClick={() => DeleteEmployee(e.employeeId)}>
                          {/* <i className="ti ti-trash fs-5" /> */}
                          <Delete className="fs-5" />
                        </a>
                      </div>
                    </td>
                  </tr>
                })}

                {/* end row */}
                <ToastContainer />
              </tbody>
            </table>
            <div className="d-flex align-items-center justify-content-end py-1">
              <p className="mb-0 fs-2">
                Rows per page:
              </p>
              <select
                className="form-select w-auto ms-0 ms-sm-2 me-8 me-sm-4 py-1 pe-7 ps-2 border-0"
                aria-label="Items per page"
                onChange={(e) => {
                  const selectedItemsPerPage = parseInt(e.target.value, 10);
                  // You can update the itemsPerPage state here
                }}
              >
                <option selected>5</option>
                <option value="10">10</option>
                <option value="25">25</option>
              </select>
              <p className="mb-0 fs-2">
                {currentPage * itemsPerPage + 1} -{" "}
                {Math.min((currentPage + 1) * itemsPerPage, EmployeeDatailes.length)} of{" "}
                {EmployeeDatailes.length}
              </p>
              <nav aria-label="...">
                <ul className="pagination justify-content-center mb-0 ms-8 ms-sm-9">
                  <li className="page-item p-1">
                    <a
                      className="page-link border-0 rounded-circle text-dark fs-6 round-32 d-flex align-items-center justify-content-center"
                      href="#"
                      onClick={() => {
                        if (currentPage > 0) {
                          handlePageChange(currentPage - 1);
                        }
                      }}
                    >
                      {/* <i className="ti ti-chevron-left" /> */}
                      <ArrowLeft />
                    </a>
                  </li>
                  <li className="page-item p-1">
                    <a
                      className="page-link border-0 rounded-circle text-dark fs-6 round-32 d-flex align-items-center justify-content-center"
                      href="#"
                      onClick={() => {
                        if (currentPage < Math.ceil(EmployeeDatailes.length / itemsPerPage) - 1) {
                          handlePageChange(currentPage + 1);
                        }
                      }}
                    >
                      <ArrowRight />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>

          </div>
        </div>
      </div>

      <div class="card-body">
        <div
          class="modal fade"
          id="addRowModal1"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          data-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title">
                  {EmpID == 0 ? (
                    <span class="fw-mediumbold">Add Employee</span>
                  ) : (
                    <span class="fw-mediumbold">Edit Employee</span>
                  )}
                </h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clearForm}></button>
              </div>

              <div class="modal-body">
                <AddOrEditEmployee employeeID={EmpID} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainGrid;
