import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Product_Services from '../Api/Products_Services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import VersionForm from './VersionForm';
// import { IMAGE_URL } from '../../env';
import { CloudDownload } from '@mui/icons-material';
import SignalRService from '../Api/SignalR_Service';

const prodObj = new Product_Services();
const signalRService = new SignalRService(); // Initialize your SignalR service

const AddUpdateProducts = ({ productId, productEditList }) => {

    const [productVersion, setProductVersion] = useState([{
        productId: 0, productVersionId: 0, version: "", versionExePath: "", androidExePath: "", iosExePath: "", webApplicationURl: "", description: "", status: "AC",
        createdBy: 1
    }]);
    // const [uploadProgress, setUploadProgress] = useState(0);

    const [helpDocument, setHelpDocument] = useState('');
    const [Liscense, setLiscense] = useState('');
    const [guideDocument, setguideDocument] = useState('');
    const [prodId, setproductId] = useState(0);
    const [prodImageID, setProdImgId] = useState(0);
    const [about, setAbout] = useState('');
    const [dependenciesValue, setDependenciesValue] = useState('');
    const [productName, setProductName] = useState(null);
    const [developer, setDeveloper] = useState(null);
    const [size, setSize] = useState(null);
    const [selectedDeviceType, setSelectedDeviceType] = useState([]);
    const [language, setLanguage] = useState(null);
    const [version, setVersion] = useState(null);
    const [productLogo, setProductLogo] = useState('');
    const [windowScreen, setWindow] = useState(false);
    const [Android, setAndroid] = useState(false);
    const [IOS, setIos] = useState(false);
    const [webApplication, setWebAPplication] = useState(false);
    const [allFiles, setAllFiles] = useState([]);
    const [folderName, setFolderName] = useState([]);
    const [progress, setProgress] = useState(0);

    const formData = new FormData();
    const [Error, setError] = useState({
        productName: "",
        productLogo: "",
        help: "",
        pfile: "",
        guide: "",
        language: "",
        developer: "",
        size: "",
        device: ""
    });
    const options = [
        { value: '1', label: 'Windows' },
        { value: '2', label: 'Android' },
        { value: '3', label: 'IOS' },
        { value: '4', label: "Web Application" }
    ]

    // useEffect(() => {
    //     
    //     signalRService.addProgressUpdateListener((percentage) => {
    //         
    //         setUploadProgress(percentage);
    //     });

    //     return () => {
    //         // Clean up the SignalR connection when the component unmounts
    //         signalRService.stopConnection();
    //     };
    // }, []);



    useEffect(() => {

        if (productId > 0) {

            if (productEditList != null && productEditList != undefined) {

                let ProductModelList = productEditList?.productsModels;
                let ProductVersionList = productEditList?.productVersions;
                let ProductImageList = productEditList?.productImages;
                console.log(productEditList);

                setproductId(ProductModelList[0]?.productId);
                setProductName(ProductModelList[0]?.productName);
                setDeveloper(ProductModelList[0]?.developer);
                setSize(ProductModelList[0]?.size);
                setProductLogo(ProductModelList[0]?.productLogoLink);
                setHelpDocument(ProductModelList[0]?.helpDocument);
                setguideDocument(ProductModelList[0]?.installationGuideDocument);
                setLiscense(ProductModelList[0]?.productFileLink);
                setAbout(ProductModelList[0]?.about);
                setDependenciesValue(ProductModelList[0]?.dependencies);
                setLanguage(ProductModelList[0]?.language);
                setProdImgId(ProductImageList[0]?.productImageId);
                // console.log(IMAGE_URL + productLogo);
                const SplitDeviceType = ProductImageList[0]?.deviceType;
                const resultArray = SplitDeviceType.split(",");
                const selectedOptions = [];

                resultArray.forEach((value) => {
                    const matchingOption = options.find((option) => option.label.trim() === value.trim());
                    if (matchingOption) {
                        selectedOptions.push(matchingOption);
                    }
                });

                // selectedOptions should now contain the objects for "IOS" and "Android"

                handleDeviceTypeChange(selectedOptions);
                setSelectedDeviceType(selectedOptions);

                if (ProductVersionList && ProductVersionList.length > 0) {
                    // Map the items from ProductVersionList to create an array of objects

                    const updatedProductVersion = ProductVersionList?.map((item) => ({
                        productId: item.productId,
                        productVersionId: item.productVersionId,
                        version: item.version,
                        versionExePath: item.versionExePath,
                        androidExePath: item.androidExePath,
                        iosExePath: item.iosExePath,
                        webApplicationURl: item.webApplicationURl,
                        description: item.description,
                        status: item.status,
                        createdBy: item.createdBy,
                    }));

                    // Set the state with the updatedProductVersion array

                    setProductVersion(updatedProductVersion);
                }
            }

        }
    }, [productEditList])

    const validationForm = (e) => {

        const { name, value } = e.target;
        let valid = true;
        const errors = {};
        if (name == 'productName') {
            if (value == '') {
                errors.productName = 'Product Name is Required';
                valid = false;
                setProductName('');
            }
            else {
                errors.productName = '';
                setProductName(value);
            }
        }
        else if (name == 'developer') {
            if (value == '') {
                errors.developer = 'Developer is Required';
                valid = false;
                setDeveloper('');
            }
            else {
                errors.developer = '';
                setDeveloper(value);
            }
        }
        else if (name == 'size') {

            setSize(value);

        }

        else if (name == 'language') {
            if (value == '') {
                errors.language = 'Language is Required';
                valid = false;
                setLanguage('');
            }
            else {
                errors.language = '';
                setLanguage(value);
            }
        }
        else if (name == 'versions') {
            setVersion(value);
        }
        setError((prevErrors) => ({
            ...prevErrors,
            ...errors,
        }));
        return valid;
    }

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        let valid = true;
        const errors = {};

        // Initialize empty arrays to hold new files, folder names, and errors.
        let newFiles = [];
        let newFolderNames = [];
        let newErrors = {};

        // Check which input field was changed based on its name.
        if (name === "productLogo") {
            // Handle the "productLogo" input field.
            if (files.length === 0) {
                errors.productLogo = 'Product Logo is required';
                valid = false;
            } else {
                errors.productLogo = '';
                newFiles.push(files[0]);
                setProductLogo(files[0]);
                newFolderNames.push({ foldername: 'Product Logo' });
            }
        } else if (name === "help") {
            // Handle the "help" input field.
            if (files.length === 0) {
                errors.helpDocument = 'Help Document is required';
                valid = false;
            } else {
                errors.helpDocument = '';
                newFiles.push(files[0]);
                setHelpDocument(files[0]);
                newFolderNames.push({ foldername: 'Product Help Document' });
            }
        } else if (name === "guide") {
            // Handle the "guide" input field.
            if (files.length === 0) {
                errors.guide = 'Installation Guide Document is required';
                valid = false;
            } else {
                errors.guide = '';
                newFiles.push(files[0]);
                setguideDocument(files[0]);
                newFolderNames.push({ foldername: 'Installation Guide Document' });
            }
        } else if (name === "License") {
            // Handle the "License" input field.

            errors.license = '';
            newFiles.push(files[0]);
            setLiscense(files[0]);
            newFolderNames.push({ foldername: 'License Exe File' });


        }

        // Update the state with the newly selected files and folder names.
        setAllFiles((prevData) => [...prevData, ...newFiles]);
        setFolderName((e) => [...e, ...newFolderNames]);

        // Update the errors state.
        setError((prevErrors) => ({
            ...prevErrors,
            ...errors,
        }));

        return valid;
    }



    const handleDeviceTypeChange = (selectedOption) => {

        setSelectedDeviceType(selectedOption);
        const isWindowsSelected = selectedOption.some((option) => option.value === '1');
        setWindow(isWindowsSelected);
        const isAndroidSelected = selectedOption.some((option) => option.value === '2');
        setAndroid(isAndroidSelected);
        const isIOSSelected = selectedOption.some((option) => option.value === '3');
        setIos(isIOSSelected);
        const WebApplication = selectedOption.some((option) => option.value === '4');
        setWebAPplication(WebApplication);
        // Update the customer state with the selected option object
    };


    const updateVersion = (index, value) => {

        var temp = productVersion;
        temp[index].version = value;
        setProductVersion([...temp]);
    }

    const updateDesc = (index, value) => {
        var temp = productVersion;
        temp[index].description = value;
        setProductVersion([...temp]);
    }

    const fileVersionSave = (index, value, version) => {

        // var temp = productVersion;
        // temp[index].file = value[0];
        setFolderName((e) => [...e, { foldername: version }]);
        setAllFiles((prevData) => [...prevData, value[0]]);
        // setProductVersion([...temp]);
    }

    const androidVersionSave = (index, value, version) => {

        setFolderName((e) => [...e, { foldername: 'APK_ ' + version }]);
        setAllFiles((prevData) => [...prevData, value[0]]);
    }
    const iosVersionSave = (index, value, version) => {

        setFolderName((e) => [...e, { foldername: 'IOS ' + version }]);
        setAllFiles((prevData) => [...prevData, value[0]]);
    }

    const HandleWebApplication = (index, value) => {

        var temp = productVersion;
        temp[index].webApplicationURl = value;
        setProductVersion([...temp]);
    }

    const submitForm = () => {

        let valid = true;
        const errors = {};
        console.log(about);

        if (productName == "" || productName == null) {
            toast.error(
                'Product Name is required',
                {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                }
            );
            valid = false;
        }

        else if (developer == "" || developer == null) {
            toast.error(
                'Developer is required',
                {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                }
            );
            valid = false;
        }

        else if (selectedDeviceType.length < 0) {
            toast.error(
                'Please Select device type',
                {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                }
            );
            valid = false;
        }
        // else if (size == "" || size == null) {
        //     toast.error(
        //         'Size is required',
        //         {
        //             position: toast.POSITION.TOP_RIGHT,
        //             autoClose: 2000,
        //         }
        //     );
        //     valid = false;
        // }

        else if (productLogo != null && productLogo?.length == 0) {
            toast.error(
                'Product Logo is required',
                {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                }
            );
            valid = false;
        }

        else if (helpDocument != null && helpDocument?.length == 0) {
            toast.error(
                'Help Document is required',
                {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                }
            );
            valid = false;
        }

        else if (guideDocument != null && guideDocument?.length == 0 && !webApplication) {
            toast.error(
                'Installation Guide is required',
                {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                }
            );
            valid = false;
        }
        // else if (Liscense != null && Liscense?.length == 0 && !webApplication) {
        //     toast.error(
        //         'License File is required',
        //         {
        //             position: toast.POSITION.TOP_RIGHT,
        //             autoClose: 2000,
        //         }
        //     );
        //     valid = false;
        // }
        else if (language == "" || language == null) {
            toast.error(
                'Language is required',
                {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                }
            );
            valid = false;
        }
        else if (about == "<p><br></p>" || about == "") {
            toast.error(
                'About is required',
                {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                }
            );
            valid = false;
        }
        setError(errors);
        return valid;

    };

    const handleProductSubmit = async (e) => {

        const a = document.getElementById('spin');
        console.log(allFiles);

        console.log(productVersion);
        e.preventDefault();
        var selectedDevice = selectedDeviceType && selectedDeviceType?.map(option => option.label).join(', ');
        const formValid = submitForm();
        if (formValid) {
            const data = {
                productImageId: prodImageID,
                productId: prodId,
                deviceType: selectedDevice,
                thumbnailImageLink: null,
                originalImageLink: null,
                status: "AC",
                createdBy: 1
            }
            formData.append('productId', prodId);
            formData.append('productName', productName);
            formData.append('productLogoLink', productLogo);
            formData.append('productFileLink', Liscense);
            formData.append('helpDocument', helpDocument);
            formData.append('installationGuideDocument', guideDocument);
            formData.append('about', about);
            formData.append('language', language);
            formData.append('size', size);
            formData.append('developer', developer);
            formData.append('dependencies', dependenciesValue);
            formData.append('status', "AC");
            formData.append('createdBy', 1);
            formData.append('ProductVersionData', JSON.stringify(productVersion));
            if (allFiles.length > 0) {
                for (let i = 0; allFiles.length > i; i++) {
                    formData.append('documents', allFiles[i]);
                }
            }
            else {
                formData.append('documents', JSON.stringify(allFiles));
            }

            formData.append('FolderNameList', JSON.stringify(folderName));
            formData.append('files', {});
            formData.append('ProductImagesData', JSON.stringify(data));
            // signalRService.startConnection();

            const istrue = productVersion.filter((e) => e.version == "");
            if (istrue.length > 0) {
                toast.error(
                    'Enter Version Details',
                    {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    }
                );
                return;
            }
            toast.success(
                'Upload Starting please wait for moments',
                {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                }
            );
            a.style.display = "block";

            await prodObj.AddUpdate_Products(formData).then((res) => {
                if (res != null) {
                    // signalRService.stopConnection();
                    if (prodId > 0) {
                        a.style.display = "none";
                        toast.success(
                            'Product Updated Succesfully',
                            {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            }
                        );

                    }
                    else {
                        a.style.display = "none";
                        toast.success(
                            'Product Created Succesfully',
                            {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            }
                        );
                    }


                    setTimeout(() => {

                        window.location.reload();
                    }, 1500);
                }
                else {
                    toast.error(
                        'Uploaded Failed, Please try again..!',
                        {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 1000,
                        }
                    );
                }
            }).catch((err) => {
                a.style.display = "none";
                console.log(err);
            });
        }
        else {
            a.style.display = "none";
        }
    }
    const close = (e) => {

        e.preventDefault();
        window.location.reload();
    }

    return (
        <form id="addProductModalTitle" encType='multipart/form-data' autoComplete='off' onSubmit={handleProductSubmit}>
            <div className="row">
                <div className="col-md-4">
                    <div class="form-group mb-3">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Product Name<span className='text-danger'>*</span></label>
                        <input type="text" id="productName" name='productName' value={productName} className="form-control" onChange={validationForm} />
                        {Error.productName && <span className="validation-text text-danger">{Error.productName}</span>}
                    </div>
                </div>
                <div className="col-md-4">
                    <div class="form-group mb-3">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Developer<span className='text-danger'>*</span></label>
                        <input type="text" id="developer" name='developer' value={developer} className="form-control" onChange={validationForm} />
                        {Error.developer && <span className="validation-text text-danger">{Error.developer}</span>}
                    </div>
                </div>
                <div className="col-md-4">
                    <div class="form-group mb-3">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Device Type <span className='text-danger'>*</span></label>

                        <Select options={options} theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: '#2a3447',
                                primary: '#2a3447',
                            },
                        })}
                            closeMenuOnSelect={false}
                            isMulti
                            backspaceRemovesValue={true}
                            id="inlineFormCustomSelect"
                            className='customerList'
                            value={selectedDeviceType}
                            onChange={handleDeviceTypeChange} />
                        {Error.device && <span className="validation-text text-danger">{Error.device}</span>}
                    </div>
                </div>
                {!webApplication && <div className="col-md-4">
                    <div class="form-group mb-3">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Application Size<span className='text-danger'></span></label>
                        <input type="text" id="size" name='size' value={size} className="form-control" onChange={validationForm} />
                        {Error.size && <span className="validation-text text-danger">{Error.size}</span>}
                    </div>
                </div>}
                <div className="col-md-4">
                    <div class="form-group mb-3">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Product Logo<span className='text-danger'>*</span></label>
                        <input type="file" id="logo" name='productLogo' className="form-control" onChange={handleFileChange} />
                        {prodId > 0 && productLogo != null && productLogo != "" ? <a href={productLogo} target="_blank">
                            <CloudDownload />
                        </a> : <></>}
                        {Error.productLogo && <span className="validation-text text-danger">{Error.productLogo}</span>}
                    </div>
                </div>
                <div className="col-md-4">
                    <div class="form-group mb-3">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Product Help Document<span className='text-danger'>*</span></label>
                        <input type="file" id="help" name='help' className="form-control" onChange={handleFileChange} />
                        {prodId > 0 && helpDocument != null && helpDocument != "" ? <a href={helpDocument} target="_blank">
                            <CloudDownload />
                        </a> : <></>}
                        {Error.help && <span className="validation-text text-danger">{Error.help}</span>}

                    </div>
                </div>
                {!webApplication && <div className="col-md-4">
                    <div class="form-group mb-3">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Installation Guide Document<span className='text-danger'>*</span></label>
                        <input type="file" id="guide" name='guide' className="form-control" onChange={handleFileChange} />
                        {prodId > 0 && guideDocument != null && guideDocument != "" ? <a href={guideDocument} target="_blank">
                            <CloudDownload />
                        </a> : <></>}
                        {Error.guide && <span className="validation-text text-danger">{Error.guide}</span>}
                    </div>
                </div>}
                {!webApplication &&
                    <div className="col-md-4">
                        <div class="form-group mb-3">
                            <label class="mr-sm-2" for="inlineFormCustomSelect">License Generater File Upload (.exe)<span className='text-danger'></span></label>
                            <input type="file" id="License" name='License' className="form-control" onChange={handleFileChange} />
                            {prodId > 0 && Liscense != null && Liscense != "" ? <a href={Liscense} target="_blank">
                                <CloudDownload />
                            </a> : <></>}
                            {Error.guide && <span className="validation-text text-danger">{Error.guide}</span>}
                        </div>
                    </div>
                }

                <div className="col-md-4">
                    <div class="form-group mb-3">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Language<span className='text-danger'>*</span></label>
                        <input type="text" id="language" name='language' value={language} className="form-control" onChange={validationForm} />
                        {Error.language && <span className="validation-text text-danger">{Error.language}</span>}
                    </div>
                </div>





                {/* {windowScreen && <div className="col-md-4">
                    <div class="form-group mb-3">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Windows ScreenShots<span className='text-danger'></span></label>
                        <input type="file" id="W-screenshots" name='windowScreenshots' className="form-control" multiple accept=".png, .jpg, .jpeg" />
                        <span className="validation-text text-danger" />
                    </div>
                </div>}
                {Android && <div className="col-md-4">
                    <div class="form-group mb-3">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Android ScreenShots<span className='text-danger'></span></label>
                        <input type="file" id="A-screenshots" name='androidScreenshots' className="form-control" multiple accept=".png, .jpg, .jpeg" />
                        <span className="validation-text text-danger" />
                    </div>
                </div>}
                {IOS &&
                    <div className="col-md-4">
                        <div class="form-group mb-3">
                            <label class="mr-sm-2" for="inlineFormCustomSelect">IOS ScreenShots<span className='text-danger'></span></label>
                            <input type="file" id="I-screenshots" name='iosScreenshots' className="form-control" multiple accept=".png, .jpg, .jpeg" />
                            <span className="validation-text text-danger" />
                        </div>
                    </div>} */}

                <div className="col-md-12">
                    <div class="form-group mb-3">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">About<span className='text-danger'>*</span></label>
                        <div className="card">
                            <div className="card-body">
                                <ReactQuill theme="snow" value={about} onChange={setAbout} modules={AddUpdateProducts.modules}
                                    formats={AddUpdateProducts.formats} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div class="form-group mb-3">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Dependencies</label>
                        <div className="card">
                            <div className="card-body">
                                <ReactQuill theme="snow" value={dependenciesValue} onChange={setDependenciesValue} modules={AddUpdateProducts.modules}
                                    formats={AddUpdateProducts.formats} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                {productVersion.map((e, index) => {
                    return (
                        <VersionForm index={index} UpdateVersion={updateVersion} UpdateDesc={updateDesc} setProductVersion={setProductVersion} productVersion={e} prodId={prodId} fileVersionSave={fileVersionSave} List={productVersion}
                            Android={Android} IOS={IOS} androidVersionSave={androidVersionSave} iosVersionSave={iosVersionSave} webApplication={webApplication} HandleWebApplication={HandleWebApplication} />
                    )

                })}
            </div>

            <div className='text-end'>
                {/* <div className="progress-bar" style={{ width: `${uploadProgress}%` }}>
                    {uploadProgress}%
                </div> */}
                <button id="btn-edit" type="submit" className="btn btn-success rounded-pill px-4">Save</button>
                <button className="btn btn-danger rounded-pill px-4 ms-2" data-bs-dismiss="modal" onClick={close}> Discard </button>

            </div>

            <ToastContainer />
        </form>
    )

}


AddUpdateProducts.modules = {
    toolbar: [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ["link", "image"],
        ["code-block"],
        ["clean"],
    ],
};

AddUpdateProducts.formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "align",
    "color",
    "background",
    "link",
    "image",
    "code-block",
];
export default AddUpdateProducts;

