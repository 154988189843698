import s11 from "../assets/images/logos/cardio.png";
import s5 from "../assets/images/logos/testflowpro.png";
import { useState, useEffect } from "react";
import Product_Services from "./Api/Products_Services";
import { useNavigate } from "react-router-dom";
import { Menu, Search } from "@mui/icons-material";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

const prodObj = new Product_Services();
const Home = () => {
    const [getProductList, setProductList] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        get_ProductLIst()
    }, []);
    const get_ProductLIst = async () => {
        const res = await prodObj.getProductsList();
        if (res != null) {

            console.log(res);
            setProductList(res);
        }
    }

    const getPlainText = (htmlString) => {
        const element = document.createElement("div");
        element.innerHTML = htmlString;

        // Use a regular expression to remove HTML tags
        const textContent = element.textContent || element.innerText;
        const strippedText = textContent.replace(/<[^>]*>/g, '');

        return strippedText;
    };

    const filteredProductList = getProductList.filter((product) =>
        product.productName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const navigateToVersionPage = (id) => {
        navigate('/versions', {
            state: { productId: id },

        });
    }
    const TruncatedText = (text, maxLength) => {
        return (text.length > maxLength ? text.substring(0, maxLength) + '...' : text != null || text != "" ? text : "");

    }
    return (
        <div>

            <div className="card bg-light-info shadow-none position-relative overflow-hidden">
                <div className="card-body px-4 py-3">
                    <div className="row align-items-center">
                        <div className="col-9">
                            <h4 className="fw-semibold mb-8">Home</h4>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    {/* <li className="breadcrumb-item"><a className="text-muted " href="./index.html">Dashboard</a></li>
                                        <li className="breadcrumb-item" aria-current="page">Home</li> */}
                                </ol>
                            </nav>
                        </div>
                        <div className="col-3">
                            <div className="text-center mb-n5">
                                <img src="../../dist/images/breadcrumb/ChatBc.png" alt="" className="img-fluid mb-n4" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card position-relative overflow-hidden">
                <div className="shop-part d-flex w-100">

                    <div className="card-body p-4 pb-0">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <a className="btn btn-primary d-lg-none d-flex" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                                {/* <i className="ti ti-menu-2 fs-6" /> */}
                                <Menu />
                            </a>
                            <h5 className="fs-5 fw-semibold mb-0 d-none d-lg-block">Products</h5>
                            <form className="position-relative">
                                <input type="text" className="form-control search-chat py-2 ps-5" id="text-srh" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                                    placeholder="Search Product" />
                                {/* <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3" /> */}
                                <Search className="position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3" />
                            </form>
                        </div>
                        <div className="row">
                            {filteredProductList?.length > 0 && filteredProductList.map((e, i) => {

                                return <div className="col-sm-6 col-xl-4" key={i}>
                                    <div className="card hover-img overflow-hidden rounded-2">
                                        <div className="products position-relative">
                                            <a onClick={() => navigateToVersionPage(e.productId)}><img src={e?.productLogoLink} alt="..." /></a>
                                        </div>
                                        <div className="card-body pt-3 p-4">
                                            <h6 className="fw-semibold fs-4">{e?.productName}</h6>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <Tooltip title={getPlainText(e?.about)} placement="right">
                                                    <h6 className="fw-semibold fs-4 mb-0"><span className="fw-normal text-muted fs-3" dangerouslySetInnerHTML={{ __html: TruncatedText(e?.about, 150) }} ></span></h6>

                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            })}

                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
export default Home;