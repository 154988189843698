import Logo from "../assets/images/logos/vaanamwhiteLogo.png";
const Loader = () => {
    return (

        <>
            < div className="preloader" style={{ display: 'none' }} id="spin">
                <div className="lds-ripple img-fluid">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div >

        </>
    );
}
export default Loader;