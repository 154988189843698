import React, { useState, useContext, useEffect, useReducer } from "react";
import PurhcaseService from "../Api/PurchaseService";
import AddOrEditPurhcase from "./AddOrEditPurchase";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { AddShoppingCart, ArrowLeft, ArrowRight, Delete, Email, RemoveRedEyeOutlined, Search } from "@mui/icons-material";
import Swal from "sweetalert2";

const PurhcaseServiceObj = new PurhcaseService();

const PurchaseGrid = (props) => {
  // const {cookies} =  useContext(AppContext);
  const [PurchaseId, SetPurchaseId] = useState(0);
  const [GetPurchaseList, SetGetPurchaseList] = useState([]);
  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const [ModelName, setModelName] = useState("");
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(5);
  // Current page
  const itemsPerPage = parseInt(pageNumber);
  const [retrievedData, setRetriveData] = useState({});

  // const [priviledge, setPriviledge] = useState(cookies.UserPrivileges);
  function HandleClick(id) {
    SetPurchaseId(id);
    if (id == 0) {
      setModelName("Purchase Create");
    } else {
      setModelName("Purchase Edit");
    }
    //PageRender();
  }

  useEffect(() => {
    get_email()

  }, [])


  const getItemsForCurrentPage = () => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return GetPurchaseList.slice(startIndex, endIndex);
  };

  const itemsToDisplay = getItemsForCurrentPage();

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  function DeletePurchase(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this Purchase?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, ignore'
    }).then((result) => {
      if (result.isConfirmed) {

        PurhcaseServiceObj.DeletePurchase(id)
          .then((res) => {
            toast.success(
              'Purchase deleted succesfully',
              {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
              }
          );
            setTimeout(() => {
              window.location.reload();
          }, 1500);
            setGridRender();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }
  useEffect(() => {
    PurhcaseServiceObj.GetPurchaseList()
      .then((res) => {

        if (res != null && res.data != null && res.data.length > 0) {
          SetGetPurchaseList([...res.data]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // setTimeout(() => {
    //   $('#AssociateDisplay').DataTable().destroy();
    //   setUsers([]);
    // },2000);
  }, [GridRender, Render]);



  function refreshPage() {
    window.location.reload(false);
  }



  const clearForm = (e) => {

    e.preventDefault();
    window.location.reload();
  };
  const get_email = async () => {

    const storedData = localStorage.getItem('EmailMessage');
    if (storedData) {
      // retrievedData = JSON.parse(storedData);
      setRetriveData(JSON.parse(storedData));
    }
  }

  const Resend_email = async (data) => {

    const a = document.getElementById('spin');
    a.style.display = "block";
    const res = await PurhcaseServiceObj.sendEmail(data);

    if (res.data == true) {
      a.style.display = "none";
      toast.success(
        'Email sent successfully',
        {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
        }
    );
    }
    else {
      a.style.display = "none";
      toast.error(
        'Failed to sent',
        {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
        }
    );
    }
  }
  return (
    <>

      <div className="card bg-light-info shadow-none position-relative overflow-hidden">
        <div className="card-body px-4 py-3">
          <div className="row align-items-center">
            <div className="col-9">
              <h4 className="fw-semibold mb-8">Order List</h4>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a className="text-muted " href="/home">Home</a></li>
                  <li className="breadcrumb-item" aria-current="page">Order List</li>
                </ol>
              </nav>
            </div>
            <div className="col-3">
              <div className="text-center mb-n5">
                <img alt="" className="img-fluid mb-n4" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="widget-content searchable-container list">
        {/* --------------------- start Contact ---------------- */}
        <div className="card card-body">
          <div className="row">
            <div className="col-md-4 col-xl-3">
              <form className="position-relative">
                <input type="text" className="form-control product-search ps-5" id="input-search" placeholder="Search Orders..." />
                <Search className="position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3" />
              </form>
            </div>
            <div className="col-md-8 col-xl-9 text-end d-flex justify-content-md-end justify-content-center mt-3 mt-md-0">
              <div className="action-btn show-btn" style={{ display: 'none' }}>
                <a href="#" className="delete-multiple btn-light-danger btn me-2 text-danger d-flex align-items-center font-medium">
                  <i className="ti ti-trash text-danger me-1 fs-5" /> Delete All Row
                </a>
              </div>
              <a href="#" id="btn-add-contact" className="btn btn-info d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#addRowModal1">
                <AddShoppingCart />

                Add Order
              </a>
            </div>
          </div>
        </div>
        {/* ---------------------
                              end Contact
                          ---------------- */}
        {/* Modal */}

        <div className="card card-body">
          <div className="table-responsive">
            <table className="table search-table align-middle text-nowrap">
              <thead className="header-item">
                <tr>
                  <th>Product</th>
                  <th>Customer</th>
                  <th> OrderCode</th>
                  <th>Subscription(In Month)</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr></thead>
              <tbody>
                {/* start row */}
                {itemsToDisplay?.length > 0 && itemsToDisplay.map((e, i) => {
                  // const isEmailMatch = retrievedData?.emailId && e?.email === retrievedData?.emailId;

                  return <tr className="search-items" >

                    <td>
                      <div className="d-flex align-items-center">
                        {/* <img src={userImage1} alt="avatar" className="rounded-circle" width={35} /> */}
                        <div>
                          <div className="user-meta-info">
                            <h6 className="user-name mb-0" data-name="Emma Adams">{e.productName}</h6>
                            <p className="fs-2 mb-0 text-muted">{e.version}</p>                                                </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span>{e.customer}</span>
                    </td>
                    <td>
                      <span>{e.orderCode}</span>
                    </td>
                    <td>
                      <span>{e.periadMonth}</span>
                    </td>
                    <td>
                      <span>{e.amount}</span>
                    </td>
                    <td>
                      <div className="action-btn">

                        <a href="#" className="text-info edit ms-2" data-bs-toggle="modal" data-bs-target="#addRowModal1" onClick={() => HandleClick(e.purchaseId)}>
                          {/* <i className="ti ti-eye fs-5" /> */}
                          <RemoveRedEyeOutlined className="fs-5" />
                        </a>
                        <a href="#" className="text-dark delete ms-2" onClick={() => DeletePurchase(e.purchaseId)}>
                          {/* <i className="ti ti-trash fs-5" /> */}
                          <Delete className="fs-5" />
                        </a>


                      </div>
                    </td>
                  </tr>
                })}

                {/* end row */}
                <ToastContainer />
              </tbody>
            </table>
            <div className="d-flex align-items-center justify-content-end py-1">
              <p className="mb-0 fs-2">
                Rows per page:
              </p>
              <select
                className="form-select w-auto ms-0 ms-sm-2 me-8 me-sm-4 py-1 pe-7 ps-2 border-0"
                aria-label="Items per page"
                onChange={(e) =>
                  setPageNumber(e.target.value)
                  // You can update the itemsPerPage state here
                }
              >
                <option selected>5</option>
                <option value="10">10</option>
                <option value="25">25</option>
              </select>
              <p className="mb-0 fs-2">
                {currentPage * itemsPerPage + 1} -{" "}
                {Math.min((currentPage + 1) * itemsPerPage, GetPurchaseList.length)} of{" "}
                {GetPurchaseList.length}
              </p>
              <nav aria-label="...">
                <ul className="pagination justify-content-center mb-0 ms-8 ms-sm-9">
                  <li className="page-item p-1">
                    <a
                      className="page-link border-0 rounded-circle text-dark fs-6 round-32 d-flex align-items-center justify-content-center"
                      href="#"
                      onClick={() => {
                        if (currentPage > 0) {
                          handlePageChange(currentPage - 1);
                        }
                      }}
                    >
                      {/* <i className="ti ti-chevron-left" /> */}
                      <ArrowLeft />
                    </a>
                  </li>
                  <li className="page-item p-1">
                    <a
                      className="page-link border-0 rounded-circle text-dark fs-6 round-32 d-flex align-items-center justify-content-center"
                      href="#"
                      onClick={() => {
                        if (currentPage < Math.ceil(GetPurchaseList.length / itemsPerPage) - 1) {
                          handlePageChange(currentPage + 1);
                        }
                      }}
                    >
                      <ArrowRight />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>

          </div>
        </div>
      </div>

      <div class="card-body">
        <div
          class="modal fade"
          id="addRowModal1"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          data-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title">
                  {PurchaseId == 0 ? (
                    <span class="fw-mediumbold">Add Purchase</span>
                  ) : (
                    <span class="fw-mediumbold">Edit Purchase</span>
                  )}
                </h3>
                <button type="button" class="btn-close" aria-label="Close" onClick={clearForm}></button>
              </div>

              <div class="modal-body">
                <AddOrEditPurhcase purchaseId={PurchaseId} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PurchaseGrid;
