import { useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CloudDownload, Delete, Send } from '@mui/icons-material';
import Swal from 'sweetalert2';
import Product_Services from '../Api/Products_Services';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const VersionForm = ({ index, UpdateDesc, UpdateVersion, setProductVersion, productVersion, prodId, fileVersionSave, List, Android,
    IOS, androidVersionSave, iosVersionSave, webApplication, HandleWebApplication }) => {
    useEffect(() => {
        console.log('-------------------------------');
        console.log(productVersion);
        console.log('-------------------------------');
    }, [productVersion])

    const prodObj = new Product_Services();

    const removeForm = (index) => {

        console.log(productVersion);
        setProductVersion((prevForms) => {
            const updatedForms = [...prevForms];
            updatedForms.splice(index, 1);
            return updatedForms;
        });

    };
    const addForm = () => {
        // Clear the values of the form fields


        const newForm = {
            productId: prodId, productVersionId: 0, version: "", versionExePath: "", androidExePath: "", iosExePath: "", webApplicationURl: "", description: "", status: "AC",
            createdBy: 1
        };

        setProductVersion((prevForms) => {
            const updatedForms = [...prevForms];
            updatedForms.push(newForm);
            return updatedForms;
        });
    };

    const handle_Delete = async (Id, index) => {

        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this version!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, do it!',
            cancelButtonText: 'No, ignore'
        }).then((result) => {
            if (result.isConfirmed) {

                const productToDelete = productVersion;
                if (productToDelete.productVersionId > 0) {
                    // If the productVersionId is greater than zero, it means it's an existing form that you want to delete
                    prodObj.Licensing_ProductsVersionDelete(Id).then((res) => {
                        if (res != null) {


                            // const updatedProductVersion = [...List];
                            // updatedProductVersion.splice(index, 1);
                            // setProductVersion(updatedProductVersion);
                            removeForm(index);
                            toast.success(
                                'Product Version Deleted Successfully',
                                {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 2000,
                                }
                            );
                        } else {
                            toast.error(
                                'Failed to Delete',
                                {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 2000,
                                }
                            );
                        }
                    });
                } else {
                    // If the productVersionId is not greater than zero, it means it's a new form that you want to remove from the state
                    removeForm(index);
                }
            }
        });
    }

    return (<><div key={index} className="row">
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <div className="row" id={`row${index}`}>
                        <div className="col-md-4">
                            <div class="form-group mb-3">
                                <label class="mr-sm-2" for="inlineFormCustomSelect">Versions<span className='text-danger'>*</span></label>
                                <input
                                    type="text"
                                    name='versions'
                                    value={productVersion?.version}
                                    className="form-control"
                                    onChange={(e) => UpdateVersion(index, e.target.value)} // Pass the index
                                    key={index}
                                    disabled={productVersion?.productVersionId > 0 && productVersion?.version != "" ? true : false}
                                />

                                <span className="validation-text text-danger" />
                            </div>
                        </div>

                        {webApplication == false &&
                            <div className="col-md-4">
                                <div class="form-group mb-3">
                                    <label class="mr-sm-2" for="inlineFormCustomSelect">Desktop File Upload<span className='text-danger'>*</span></label>
                                    <input type="file" id="Pfile" name='pfile' className="form-control" onChange={(e) => fileVersionSave(index, e.target.files, productVersion?.version)} disabled={prodId > 0 && productVersion?.versionExePath != "" ? true : false} />
                                    {productVersion?.productVersionId > 0 && productVersion?.versionExePath != "" ? <a href={productVersion.versionExePath} target="_blank">
                                        <CloudDownload />
                                    </a> : <></>}
                                    <span className="validation-text text-danger" />
                                </div>
                            </div>}

                        {Android && !webApplication &&
                            <div className="col-md-4">
                                <div class="form-group mb-3">
                                    <label class="mr-sm-2" for="inlineFormCustomSelect">Android File Upload<span className='text-danger'>*</span></label>
                                    <input type="file" id="Pfile" name='pfile' className="form-control" onChange={(e) => androidVersionSave(index, e.target.files, productVersion?.version)} disabled={prodId > 0 && productVersion?.androidExePath != "" ? true : false} />
                                    {productVersion?.productVersionId > 0 && productVersion?.androidExePath != "" ? <a href={productVersion?.androidExePath} target="_blank">
                                        <CloudDownload />
                                    </a> : <></>}
                                    <span className="validation-text text-danger" />
                                </div>
                            </div>}

                        {IOS && !webApplication &&
                            <div className="col-md-4">
                                <div class="form-group mb-3">
                                    <label class="mr-sm-2" for="inlineFormCustomSelect">IOS File Upload<span className='text-danger'>*</span></label>
                                    <input type="file" id="Pfile" name='pfile' className="form-control" onChange={(e) => iosVersionSave(index, e.target.files, productVersion?.version)} disabled={prodId > 0 && productVersion?.iosExePath != "" ? true : false} />
                                    {productVersion?.productVersionId > 0 && productVersion?.iosExePath != "" ? <a href={productVersion.iosExePath} target="_blank">
                                        <CloudDownload />
                                    </a> : <></>}
                                    <span className="validation-text text-danger" />
                                </div>
                            </div>}

                        {webApplication == true &&
                            <div className="col-md-4">
                                <div class="form-group mb-3">
                                    <label class="mr-sm-2" for="inlineFormCustomSelect">WebApplication Url<span className='text-danger'>*</span></label>
                                    <input
                                        type="text"
                                        name='versions'
                                        value={productVersion?.webApplicationURl}
                                        className="form-control"
                                        onChange={(e) => HandleWebApplication(index, e.target.value)} // Pass the index
                                        key={index}
                                        disabled={productVersion?.productVersionId > 0 && productVersion?.webApplicationURl != "" ? true : false}
                                    />

                                    <span className="validation-text text-danger" />
                                </div>
                            </div>}
                        <div className="col-md-12">
                            <div class="form-group mb-3">
                                <label class="mr-sm-2" for="inlineFormCustomSelect">Description</label>
                                <div className="card">
                                    <div className="card-body">
                                        <ReactQuill
                                            theme="snow"
                                            id='description'
                                            value={productVersion?.description || ''}
                                            onChange={(content, delta, source, editor) => {
                                                if (source === 'user') {
                                                    // Trigger UpdateDesc only if the change comes from the user
                                                    UpdateDesc(index, content);
                                                }
                                            }}
                                            modules={VersionForm.modules}
                                            formats={VersionForm.formats}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3 col-md-12">
                            <button className="btn rounded-pill px-4 btn-light-primary text-primary font-weight-medium waves-effect waves-light" type="button" onClick={() => addForm()}>
                                <Send />&nbsp;
                                Add
                            </button>
                            <button data-repeater-delete className="btn rounded-pill px-4 btn-light-danger text-danger font-weight-medium waves-effect waves-light m-l-10" type="button" onClick={() => handle_Delete(productVersion?.productVersionId, index)} >
                                <Delete />
                                Delete Form
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <ToastContainer />
    </>

    )
}


VersionForm.modules = {
    toolbar: [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ["link", "image"],
        ["code-block"],
        ["clean"],
    ],
};

VersionForm.formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "align",
    "color",
    "background",
    "link",
    "image",
    "code-block",
];

export default VersionForm;