import userImage1 from "../assets/images/profile/user-1.jpg";
import userImage2 from "../assets/images/profile/user-2.jpg";
import userImage3 from "../assets/images/profile/user-3.jpg";

const FAQ = () => {
    return (
        <div>
            <div className="container-fluid">
                <div className="card bg-light-info shadow-none position-relative overflow-hidden">
                    <div className="card-body px-4 py-3">
                        <div className="row align-items-center">
                            <div className="col-9">
                                <h4 className="fw-semibold mb-8">FAQ</h4>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a className="text-muted" href="/home">Home</a></li>
                                        <li className="breadcrumb-item" aria-current="page">FAQ</li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-3">
                                <div className="text-center mb-n5">
                                    <img src="../../dist/images/breadcrumb/ChatBc.png" alt="" className="img-fluid mb-n4" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="text-center mb-7">
                            <h3 className="fw-semibold">Frequently asked questions</h3>
                            <p className="fw-normal mb-0 fs-4">Get to know more about ready-to-use admin dashboard templates</p>
                        </div>
                        <div className="accordion accordion-flush mb-5 card position-relative overflow-hidden" id="accordionFlushExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingOne">
                                    <button className="accordion-button collapsed fs-4 fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        What is an Admin Dashboard?
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body fw-normal">
                                        Admin Dashboard is the backend interface of a website or an application that helps to manage the
                                        website's overall content and settings. It is widely used by the site owners to keep track of their website,
                                        make changes to their content, and more.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingTwo">
                                    <button className="accordion-button collapsed fs-4 fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        What should an admin dashboard template include?
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body fw-normal">
                                        Admin dashboard template should include user &amp; SEO friendly design with a variety of components and
                                        application designs to help create your own web application with ease. This could include customization
                                        options, technical support and about 6 months of future updates.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingThree">
                                    <button className="accordion-button collapsed fs-4 fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                        Why should I buy admin templates from AdminMart?
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body fw-normal">
                                        Adminmart offers high-quality templates that are easy to use and fully customizable. With over 101,801
                                        happy customers &amp; trusted by 10,000+ Companies. AdminMart is recognized as the leading online source
                                        for buying admin templates.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingfour">
                                    <button className="accordion-button collapsed fs-4 fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
                                        Do Adminmart offers a money back guarantee?
                                    </button>
                                </h2>
                                <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body fw-normal">
                                        There is no money back guarantee in most companies, but if you are unhappy with our product, Adminmart
                                        gives you a 100% money back guarantee.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card bg-light-info rounded-2">
                    <div className="card-body text-center">
                        <div className="d-flex align-items-center justify-content-center mb-4 pt-8">
                            <a href="#">
                                <img src={userImage3} className="rounded-circle me-n2 card-hover border border-2 border-white" width={44} height={44} />
                            </a>
                            <a href="#">
                                <img src={userImage2} className="rounded-circle me-n2 card-hover border border-2 border-white" width={44} height={44} />
                            </a>
                            <a href="#">
                                <img src={userImage1} className="rounded-circle me-n2 card-hover border border-2 border-white" width={44} height={44} />
                            </a>
                        </div>
                        <h3 className="fw-semibold">Still have questions</h3>
                        <p className="fw-normal mb-4 fs-4">Can't find the answer your're looking for ? Please chat to our friendly team.</p>
                        <a href="#" className="btn btn-primary mb-8">Chat with us</a>
                    </div>
                </div>
            </div>
            <div className="dark-transparent sidebartoggler" />
        </div>
    );
}
export default FAQ;