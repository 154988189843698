import Logo from "../assets/images/logos/vaanamwhiteLogo.png";
import Background from "../assets/images/backgrounds/login-security.svg";
import facebook from "../assets/images/svgs/facebook-icon.svg";
import google from "../assets/images/svgs/google-icon.svg";
import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import AuthenticationService from "./Api/AuthenticationService"
import Swal from "sweetalert2";
import {
    Encrypt,
    Decrypt,
} from "./Api/CommonService";
import Global from "./Api/Global";
import { AppContext } from "./Api/ContextProvider";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const _loginService = new AuthenticationService();
const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [errorpassword, seterrorpassword] = useState(false);
    const [errorusername, seterrorusername] = useState(false);
    const [inputType, setInputType] = useState('password');

    const { showLoading, hideLoading, setCookies, tempModulePrivileage, isAuth, SetisAuth } =
        useContext(AppContext);
    const Email = useRef();
    const Password = useRef();
    const navigate = useNavigate();
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "Username") {
            if (value === '' || value === null) {
                seterrorusername(true);
            }
            else {
                seterrorusername(false);
            }
        }
        else if (name === "password") {
            if (value === '' || value === null) {
                seterrorpassword(true);
            }
            else {
                seterrorpassword(false);
            }
        }
    };
    const onSubmit = async (e) => {

        const a = document.getElementById('spin');

        e.preventDefault();
        let flag = 0;
        if (Password.current.value == null || Password.current?.value === '') {
            seterrorpassword(true);
            flag = 1;
        }
        else {
            seterrorpassword(false);
        }
        if (Email.current.value == null || Email.current?.value === '') {
            seterrorusername(true);
            flag = 1;
        }
        else {
            seterrorusername(false);
        }
        if (flag === 0) {
            a.style.display = "block";
            const data = {
                Username: Email.current?.value,
                Password: Password.current?.value,
                Type: "Form"
            }
            
            console.log(data);
            await _loginService.Login(data).then((response) => {
                
                console.log(response);
                if (!response.data.isSuccess) {
                    a.style.display = "none";
                    Toast.fire({
                        icon: "error",
                        title: "Login Failed",
                    });

                } else {
                    a.style.display = "none";

                    Toast.fire({
                        icon: "success",
                        title: "Login Success",
                    });
                    SetisAuth(true);
                    localStorage.setItem(Global.COOKIES.IsAuth, true);
                    localStorage.setItem("UserName", Email.current.value);
                    localStorage.setItem("Password", Encrypt(Password.current?.value));
                    localStorage.setItem("token", response?.data?.token);


                    navigate("/home", { replace: true });
                }

            }).catch((exception) => {
                a.style.display = "none";

                console.log(exception);
            });
        }
    };

    const togglePasswordVisibility = () => {

        setInputType((prevType) => (prevType === 'password' ? 'text' : 'password'));
    };

    return (
        <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed">
            < div className="preloader" style={{ display: 'none' }} id="spin">
                <div className="lds-ripple img-fluid">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div >
            <div className="position-relative overflow-hidden radial-gradient min-vh-100">
                <div className="position-relative z-index-5">
                    <div className="row">
                        <div className="col-xl-7 col-xxl-7">
                            <a href="#" target="_blank" className="text-nowrap logo-img d-block px-4 py-9 w-100">
                                <img src={Logo} alt="" />
                            </a>
                            <div className="d-none d-xl-flex align-items-center justify-content-center" style={{ height: 'calc(100vh - 100px)' }}>
                                <img src={Background} alt="" className="img-fluid" width={500} />
                            </div>
                        </div>
                        <div className="col-xl-5 col-xxl-5">
                            <div className="authentication-login min-vh-100 bg-body row justify-content-center align-items-center p-4">
                                <div className="col-sm-8 col-md-6 col-xl-9">
                                    <h2 className="mb-3 fs-7 fw-bolder">Welcome to Vaanam Technologies</h2>
                                    <form>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Username</label>
                                            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" ref={Email} onChange={handleChange} />
                                            {errorusername && (
                                                <p className="text-danger mt-1">Username is required</p>
                                            )}
                                        </div>
                                        <div className="mb-4">
                                            <label htmlFor="exampleInputPassword1" className="form-label">
                                                Password
                                            </label>
                                            <div class="input-group mb-3">
                                                <input
                                                    type={inputType}
                                                    className={`form-control ${errorpassword ? 'is-invalid' : ''}`}
                                                    id="exampleInputPassword1"
                                                    ref={Password}
                                                    onChange={handleChange}
                                                />
                                                <button class="btn btn-light-info text-info font-medium" type="button" onClick={togglePasswordVisibility}>   {inputType === 'password' ? <VisibilityOff /> : <Visibility />}</button>
                                            </div>
                                            {errorpassword && <p className="text-danger mt-1">Password is required</p>}
                                        </div>



                                        <div className="d-flex align-items-center justify-content-between mb-4">
                                            <div className="form-check">
                                                <input className="form-check-input primary" type="checkbox" defaultValue id="flexCheckChecked" defaultChecked />
                                                <label className="form-check-label text-dark" htmlFor="flexCheckChecked">
                                                    Remeber this Device
                                                </label>
                                            </div>
                                            <a className="text-primary fw-medium" href="/forgot">Forgot Password ?</a>
                                        </div>
                                        <input type="button" className="btn btn-primary w-100 py-8 mb-4 rounded-2" value="Sign In" onClick={onSubmit} />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default Login;