import React, { version } from 'react';
import './productLink.css';
import { ArrowDownwardRounded, ArrowDownwardTwoTone, ArrowDropDownOutlined, CloudDownload } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Decrypt } from '../Api/CommonService';


export default function ProductLink() {
    const location = useLocation();
    const [versionList, setversionList] = useState([]);
    const [versionExepath, setExepath] = useState(null);
    const [androidExepath, setAndroidpath] = useState(null);
    const [iosExePath, setIOSpath] = useState(null);
    // const [productId, setproductId] = location.state?.productId ?? 0;
    useEffect(() => {
        
        let a = localStorage.getItem('versionList');
        let Dec = Decrypt(a);
        let versionList = Dec;
        let productversionId = localStorage.getItem('productversionId');
        console.log(productversionId);
        const List = versionList && versionList.filter((e) => e.productVersionId == productversionId);
        setExepath(List[0]?.versionExePath);
        setAndroidpath(List[0]?.androidExePath);
        setIOSpath(List[0]?.iosExePath);
        setversionList(versionList);
        console.log(a);
    }, []);


    const getPlainText = (htmlString) => {
        return `${htmlString}`;
    };

    const formatDate = (inputDate) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(inputDate);
        return date.toLocaleDateString('en-US', options);
    };
    return (
        <div className="container mt-1">
            <div className="row">
                <div className="col-md-2 text-center ">
                    <img src={versionList && versionList[0]?.productLogoLink} alt="Avatar" />
                </div>

                <div className="col-md-2 titletext text-center">
                    <span className='mt-2'>Product Name</span>
                    <h6>{versionList[0]?.productName}</h6>
                </div>

                <div className="col-md-2 titletext text-center" >
                    <span className='mt-2'>Desktop EXE File</span><br></br>
                    <a href={versionExepath != null ? versionExepath : ''} target="_blank" >
                        <CloudDownload style={{ color: '#3949AB' }} /></a>
                </div>

                {androidExepath != null && androidExepath != "" ? <div className="col-md-2 titletext text-center" >
                    <span className='mt-2'>Android APK File</span><br></br>
                    <a href={androidExepath != null ? androidExepath : ''} target="_blank" >
                        <CloudDownload style={{ color: '#3949AB' }} /></a>
                </div>
                    : ""}


                {iosExePath != null && iosExePath != "" ? <div className="col-md-2 titletext text-center" >
                    <span className='mt-2'>IOS File</span><br></br>
                    <a href={iosExePath != null ? iosExePath : ''} target="_blank" >
                        <CloudDownload style={{ color: '#3949AB' }} /></a>
                </div>
                    : ""}

                <div className="col-md-2 titletext text-center" >
                    <span className='mt-2'>Liscense EXE File</span><br></br>
                    <a href={versionList && versionList[0]?.productFileLink} target="_blank" >
                        <CloudDownload style={{ color: '#3949AB' }} /></a>
                </div>

                <div className="col-md-2 titletext text-center" >
                    <span className='mt-2'>Installation Guide Document</span><br></br>
                    <a href={versionList && versionList[0]?.installationGuideDocument} target="_blank" >
                        <CloudDownload style={{ color: '#3949AB' }} /></a>
                </div>

                <div className="col-md-10 titletext text-center">
                    <span className='mt-2'>Help Document</span><br></br>
                    <a href={versionList && versionList[0]?.helpDocument} className='text-left' target="_blank" >
                        <CloudDownload style={{ color: '#3949AB' }} /></a>
                </div>

                <hr className="solid" />
                <div className="row row-cols-lg-11">
                    <div className="col-2">
                        <p style={{ color: '#bbb' }}>Version</p>
                        <p style={{ color: 'grey' }}>{versionList && versionList[0]?.version}</p>
                    </div>
                    <div className="col-1 vl" />
                    <div className="col-2">
                        <p style={{ color: '#bbb' }}>Language</p>
                        <p style={{ color: 'grey' }}>{versionList && versionList[0]?.language}</p>
                    </div>
                    <div className="col-1 vl" />
                    <div className="col-2">
                        <p style={{ color: '#bbb' }}>Size</p>
                        <p style={{ color: 'grey' }}>{versionList && versionList[0]?.size}</p>
                    </div>
                    <div className="col-1 vl" />
                    <div className="col-2">
                        <p style={{ color: '#bbb' }}>Developer</p>
                        <p style={{ color: 'grey' }}>{versionList && versionList[0]?.developer}</p>
                    </div>
                </div>
                {/* <div>
                    <div className="collpsTab">
                        <p className="collapsHeader1">Windows</p>
                        <button className="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                            <ArrowDropDownOutlined style={{ color: 'grey' }} />
                        </button>
                    </div>
                    <div className="collapse show" id="collapseExample" style={{ marginTop: '5px' }}>
                        <div className="card card-body">
                            <div className="photogallery1">
                                <img src="assets/Screenshot - Windows/dashboard.png" />
                                <img src="assets/Screenshot - Windows/album.png" />
                                <img src="assets/Screenshot - Windows/configuration.png" />
                                <img src="assets/Screenshot - Windows/reportTemplate.png" />
                                <img src="assets/Screenshot - Windows/reportForm.png" />
                                <img src="assets/Screenshot - Windows/reportTable.png" />
                                <p> Screenshots Update Soon</p>
                            </div>
                        </div>
                    </div>
                    <div className="collpsTab2">
                        <p className="collapsHeader1">Android</p>
                        <button className="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample2" aria-expanded="false" aria-controls="collapseExample">
                            <ArrowDropDownOutlined style={{ color: 'grey' }} />
                        </button>
                    </div>
                    <div className="collapse" id="collapseExample2" style={{ marginTop: '5px', marginBottom: '9px' }}>
                        <div className="card card-body">
                            <div className="photogallery">
                                <img src="assets/Screenshot - Android mobile/1 Splash.jpeg" />
                                <img src="assets/Screenshot - Android mobile/2 Dashboard.jpeg" />
                                <img src="assets/Screenshot - Android mobile/3 Gallery.jpeg" />
                                <img src="assets/Screenshot - Android mobile/4 Template.jpeg" />
                                <img src="assets/Screenshot - Android mobile/5 Form.jpeg" />
                                <img src="assets/Screenshot - Android mobile/6 Table.png" />
                                <p>Screenshots Update Soon</p>
                            </div>
                        </div>
                    </div>
                </div> */}
                <hr className="solid" />
                <div className="about">
                    <h6>About</h6>
                    {<p dangerouslySetInnerHTML={{ __html: getPlainText(versionList && versionList[0]?.about) }}></p>}
                </div>
                <hr className="solid" />
                {versionList && versionList.map((version, i) => {

                    if (i === 0) {
                        return (
                            <div key={i}>

                                <div className="row" key={i}>
                                    <div className="col-6 text-start">
                                        <h6>What's New</h6>
                                    </div>
                                    <div className="col-6 text-end">
                                        <button className="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseVersion" aria-expanded="false" aria-controls="collapseVersion" id="versionBtn">
                                            <p style={{ color: '#3949AB', fontSize: '14px' }}>Version History</p>
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 text-start">
                                        <p style={{ fontSize: '14px', fontWeight: 600 }}>{version?.version}</p>
                                    </div>
                                    <div className="col-6 text-end">
                                        <p style={{ color: '#3949AB', fontSize: '14px' }}>{formatDate(version?.createdDate)}</p>
                                    </div>
                                </div>
                                <div className="versionContent" dangerouslySetInnerHTML={{ __html: getPlainText(version?.description) }}>
                                    {/* <p>
                                        Licensing
                                    </p>
                                    <ul >

                                    </ul>
                                    <p>
                                        Dashboard
                                    </p>
                                    <ul>
                                        <li>
                                            USB Synchronization - Optimized USB sync.
                                        </li>
                                    </ul>
                                    <p>
                                        Configuration
                                    </p>
                                    <ul>
                                        <li>
                                            Change configuration password is included.
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <div key={i}>
                                <div className="collapse" id="collapseVersion" style={{ marginTop: '5px' }}>
                                    <hr className="solid" />
                                    <div className="row">
                                        <div className="col-6 text-start">
                                            <p style={{ fontSize: '14px', fontWeight: 600 }}>
                                                {version?.version}  {version?.status === "DEL" ? <span className='text-danger'>   &nbsp; &nbsp;(In Active)</span> : ""}
                                            </p>
                                        </div>
                                        <div className="col-6 text-end">
                                            <p style={{ color: '#3949AB', fontSize: '14px' }}>{formatDate(version?.createdDate)}</p>
                                        </div>
                                    </div>
                                    <div className="versionContent" dangerouslySetInnerHTML={{ __html: getPlainText(version?.description) }}>
                                        {/* <p>
                                            Dashboard
                                        </p>
                                        <ul>
                                            <li>
                                                Add Project - Project name field has been removed
                                            </li>
                                        </ul> */}
                                    </div>


                                </div>
                            </div>
                        );
                    }
                })}



                <hr className="solid" />
                <div className="depend" >
                    <h6>Dependencies</h6>
                    <div dangerouslySetInnerHTML={{ __html: getPlainText(versionList && versionList[0]?.dependencies) }}></div>
                    {/* <p>Windows</p>
                    <ul>
                        <li>
                            Android Studio
                        </li>
                        <li>
                            Java JDK
                        </li>
                        <li>
                            Need to make environmental setup
                        </li>
                        <li>
                            Windows firewall access for Wi-Fi Synchronization
                        </li>
                    </ul>
                    <p>Android</p>
                    <ul>
                        <li>
                            All file access permission
                        </li>
                        <li>
                            Camera / Gallery access
                        </li>
                    </ul> */}
                </div>
            </div>
        </div>
    )
}
