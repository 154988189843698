//DEV
// export const BASE_URL = "http://localhost:5005/api/";
// export const BASE_URL = "http://localhost:83/api/";

//QA
// export const BASE_URL = "http://licenseqaapi.vaanamtech.com/api/"

//PROD
// export const BASE_URL = "https://licenseapi.vaanamtech.com/api/"

//azure-prod
// export const BASE_URL = "https://vaanamlicenseapi.azurewebsites.net/api/"

//azure-prod new update
export const BASE_URL = "https://vaanamlicenseapi.azurewebsites.net/api/"
