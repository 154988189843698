import React, { useState, useReducer } from "react";
import $ from "jquery";
import { useEffect } from "react";
import PurhcaseService from "../Api/PurchaseService";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Encrypt } from "../Api/CommonService";
import PackageService from "../Api/PackageService";
const PurhcaseServiceObj = new PurhcaseService();
const packObj = new PackageService();

function AddOrEditPurhcase(props) {

  const { purchaseId, customerId } = props;
  console.log(customerId);
  const [toogle, setToggle] = useState(false);
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);
  let Renew = 0;
  const [PurchaseModel, setPurchaseModel] = useState([]);
  const [CustomerModel, setCustomerModel] = useState([]);
  const [ProductModel, setProductModel] = useState([]);
  const [ProductVersionModel, setProductVersionModel] = useState([]);
  const [device, setDeviceType] = useState(false);
  const [startDate, setStartDate] = useState();
  const [productId, setProductID] = useState(0);
  const [WindowsDevice, setWindowsDevice] = useState(false);
  const [LicenseKey, setLicenseKey] = useState("");
  const [useNumbers, setUseNumbers] = useState(true);
  const [useUpperCase, setUseUpperCase] = useState(true);
  const [password, setPassword] = useState('');
  const [packagesList, setpackagesList] = useState([]);
  const [packageId, setPackageID] = useState(0);
  // const [webApplicationURL, setWebApp] = useState();
  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const [error, setformError] = useState({
    userId: "",
    productVersionId: "",
    product: "",
    productVersionId: ""
  });

  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };

  const handleChange = (e) => {
    
    const { name, value } = e.target;
    var keyAttributeValue
    if (name == 'productPlanId') {
      if (e.target.selectedIndex !== -1) {
        var selectedOption = e.target.options[e.target.selectedIndex];
        keyAttributeValue = selectedOption.getAttribute('data-key');

        setPackageID(value);
      }
    }
    if (name == "productPlanId" && keyAttributeValue != 0) {
      var selectedText = $(".productPlanId option:selected").text().trim().split("-");
      var amount = selectedText[1]?.split("(")[0].trim();
      var periadMonth = keyAttributeValue;
      setPurchaseModel((prevState) => ({
        ...prevState,
        [name]: keyAttributeValue,
        "createdBy": 1,
        "amount": amount,
        "periadMonth": periadMonth
      }));
      if (purchaseId > 0) {
        document.getElementById("addRenewButton").style.display = "block";
      }
    }
    else {
      setPurchaseModel((prevState) => ({
        ...prevState,
        [name]: value,
        "createdBy": 1
      }));
    }


    setformError((prevState) => ({
      ...prevState,
      [name]: ""
    }));
  };
  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name == "startDate") {
      setStartDate(value);
    }
  }
  // const toogler = () => {
  //   toogle ? setToggle(false) : setToggle(true);
  // };
  const Createkey = async () => {
    const res = await PurhcaseServiceObj.LicenseKey_Create();
    if (res != null) {
      const key = res?.data[0]?.LicenseKey;
      setLicenseKey(key);
    }
  }

  const generatePassword = (passwordLength) => {
    
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let password = '';

    // Ensure at least one special character in the password
    const specialChar = '@';
    password += specialChar[Math.floor(Math.random() * specialChar.length)];

    // Generate the remaining characters
    for (let i = password.length; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }

    // Shuffle the characters to mix the special character
    password = password.split('').sort(() => Math.random() - 0.5).join('');

    setPassword(password);
  };
  useEffect(() => {
    generatePassword(6)
  }, [])

  useEffect(() => {
    if (purchaseId == 0) {
      Createkey();
    }

    PurhcaseServiceObj.GetPurchaseById(purchaseId).then((res) => {
      
      var data = res.data;
      
      if (data?.licenseKey != null && data?.licenseKey != "") {
        setLicenseKey(data?.licenseKey);
        setWindowsDevice(true);
      }
      setProductID(data?.productId);
      setPackageID(data?.packageId)
      ProductAgainst_pack(data?.productId);
      if (data?.deviceType == "Web Application" && data?.validStartDate != "") {
        setDeviceType(true);
        setStartDate(data?.validStartDate);
      }
      setPurchaseModel(data);
      if (purchaseId > 0) {
        Productversionchange(data.productId);
      }
    });
    PurhcaseServiceObj.getProductsList().then((res) => {

      setProductModel(res);
    });
    PurhcaseServiceObj.getCustomerList().then((res) => {

      setCustomerModel(res);
      // const List = res.filter((e) => e.customerId == customerId);

    });

  }, [purchaseId, GridRender, customerId]);


  const ProductIdchange = (e) => {
    
    var id = e.target.value;
    if (id != null && id != undefined) {
      setProductID(id);
      const check_Device = ProductModel && ProductModel.some((e) => e?.deviceType == "Web Application" && e?.productId == id);
      setDeviceType(check_Device);
      const array = ProductModel && ProductModel.filter((e) => e?.productId == id);
      const res = array && array.some((e) => e?.productName.includes("Cardio"));
      setWindowsDevice(res);
    }
    if (id == 0) {
      setformError((prevState) => ({
        ...prevState,
        "product": "Please select the product."
      }));
    }
    else if (id != 0) {
      setformError((prevState) => ({
        ...prevState,
        "product": ""
      }));
    }
    Productversionchange(id);
    ProductAgainst_pack(id);
  }

  const ProductAgainst_pack = async (id) => {
    
    const issuccess = await PurhcaseServiceObj.ProductAgainst_Package(id);
    if (issuccess?.data != null) {
      let getPack = issuccess?.data.map((e) => ({ label: e?.packageName, value: e?.packageId, key: e?.periodMonth }));
      setpackagesList(getPack);
    }
  }

  const Productversionchange = (id) => {
    PurhcaseServiceObj.VersionListByProductID(id).then((res) => {
      setProductVersionModel(res);
    });
  }

  const onRenew = (e) => {
    e.preventDefault();

    Renew = 1;
    setPurchaseModel((prevState) => ({
      ...prevState,
      "renew": 1,
      "createdBy": 1
    }));
    $('#PurchaseSubmit').click();
  }


  // const [password, setPassword] = useState(generatePassword(6));

  const onSubmit = (data) => {
    
    data.preventDefault();
    if (customerId > 0 && customerId != undefined) {
      PurchaseModel.userId = customerId;
    }

    var myerror = error;
    var data = PurchaseModel;
    data.licenseKey = WindowsDevice ? LicenseKey : '';
    data.renew = Renew;
    data.productId = productId;
    data.packageId = packageId;
    if (device) {
      data.deviceType = "Web Application";
      data.validStartDate = startDate;
    }
    let flag = true;
    if ($(".product option:selected").val() == 0) {
      myerror.product = "Please select the product."
      flag = false;
    }
    if (PurchaseModel.productVersionId == 0 || PurchaseModel.productVersionId == null) {
      myerror.productVersionId = "Please select the product version."
      flag = false;
    }
    if (PurchaseModel.userId == 0 || PurchaseModel.userId == null) {
      myerror.userId = "Please select the customer."
      flag = false;
    }
    if (PurchaseModel.productPlanId == 0 || PurchaseModel.productPlanId == null) {
      myerror.productPlanId = "Please select the product plan."
      flag = false;
    }
    setformError({ ...myerror });

    if (flag) {
      const a = document.getElementById('spin');
      a.style.display = "block";
      PurhcaseServiceObj.PurchaseSave(data)
        .then(async (response) => {
          
          if (response?.data?.purchaseId > 0) {
            
            console.log(response?.data);
            const name = response?.data?.customer;
            const Id = response?.data?.productId;
            const email = response?.data?.customerMail;
            const ProductId = Encrypt(response?.data?.productId);
            const productName = response?.data?.productName;
            const months = response?.data?.periadMonth;
            const OrderCode = response?.data?.orderCode;
            const LicenseKey = response?.data?.licenseKey;

            const ProductversionId = Encrypt(response.data.productVersionId);
            const fullUrl = window.location.href;
            const url = new URL(fullUrl);
            const baseUrl = `${url.protocol}//${url.host}/`;
            const redirectUrl = `${baseUrl}Client?ProductId=${ProductId}&ProductVersionId=${ProductversionId}`;
            const urlObj = new URL(redirectUrl)
            const queryParams = urlObj.searchParams;
            const webApplicationURl = response?.data?.webApplicationURl;

            // setWebApp(webApplicationURl);
            // Extract the Product ID and Product Version ID
            if (device) {
              try {
                
                const cust = CustomerModel?.filter((e) => e.customerName == name);
                const companyName = cust[0]?.companyName;
                const userName = email;
                const pass = password;
                const startDate = response?.data?.validStartDate;
                const endDate = response?.data?.validEndDate;
                const examCount = response?.data?.examCount;
                const packagename = response?.data?.packageName;
                const packageID = response?.data?.packageId;
                const custId = response?.data?.customerId;
                const adress = response?.data?.address;
                let renew = 0;
                if (response?.data?.renew != 0) {
                  renew = response?.data?.renew;
                }

                console.log(companyName);

                const examData = {
                  userName: userName,
                  password: pass,
                  companyModel: {
                    createdBy: 0,
                    createdDate: null,
                    modifiedBy: 0,
                    modifiedDate: null,
                    companyID: 0,
                    companyName: companyName,
                    customerID: custId,
                    customerName: name,
                    contactNumber: 9344013268,
                    photoLink: null,
                    address: adress,
                    isDelete: 0
                  },
                  packageModel: {
                    packageId: packageID,
                    packageName: packagename,
                    examCount: examCount,
                    validStartDate: startDate,
                    validEndDate: endDate,
                    status: "AC",
                    renew: renew
                  }
                }
                
                // Assuming packObj.Exam_save returns a Promise
                const result = await packObj.Exam_save(examData);

                // You can use the 'result' here if needed
                if (result.data > 0) {
                  // Handle the result
                  console.log("send successfull to examApp")
                }
                else {
                  console.log("fail to send examApp")
                }
              } catch (error) {
                console.log(error);
                // Handle errors here
              }
            }
            
            const array = ProductModel && ProductModel.filter((e) => e?.productId == Id);
            const check = array && array.some((e) => e?.productName.includes("Cardio"));

            let EncryptProductId = queryParams.get("ProductId");
            EncryptProductId = EncryptProductId.replace(/ /g, '+');

            let EncryptProductVersionId = queryParams.get("ProductVersionId");
            EncryptProductVersionId = EncryptProductVersionId.replace(/ /g, '+');


            const UpdateURL = `${baseUrl}Client?ProductId=${EncryptProductId}&ProductVersionId=${EncryptProductVersionId}`

            const val = response?.data?.deviceType;
            const isAndroid = val?.includes('Android');
            const androidPath = response?.data?.androidExePath;
            if (response?.data?.renew == 0) {
              const message = `
              <h5>Dear ${name},</h5></br>
              <p>We are thrilled to inform you that your recent procurement request for the software license has been successfully completed. Congratulations on this significant achievement, and thank you for choosing us for your software licensing needs.</p><br>
              <p>Here are the details of your software license procurement:</p>
              <table>
              <tr>
                  <th style="text-align: start">Software Title:</th>
                  <td style="text-align: start">${productName}</td>
              </tr>
        
              <tr>
                  <th style="text-align: start">License Duration:</th>
                  <td style="text-align: start">${months}-month(s)</td>
              </tr>
              <tr>
                  <th style="text-align: start">Order Code:</th>
                  <td style="text-align: start">${OrderCode}</td>
              </tr>
              ${LicenseKey != "" && LicenseKey != null ? `
              
              <tr>
              <th style="text-align: start">License Key:</th>
              <td style="text-align: start">${LicenseKey}</td>
          </tr>
              `: ``}
          </table>
          <p>
          After installing the cardio executable, it is necessary to input the provided license key to validate your licensing.
          </p>
          <br><br>
          <h5>
          Click the link below to download your license and the application executable:
          </h5>
          <br>
           ${device ? ` Web Link : <a href="${webApplicationURl}">${webApplicationURl}</a>` : ` Access Link : <a href="${UpdateURL}">${UpdateURL}</a>`}<br>
  
           ${device ?
                  `
            <h5>Here's your Credential:</h5>
            <p>Username: ${email}</P>
            <p>Password: ${password}</p>
           
           
            `: ``}
          ${isAndroid && androidPath != '' && androidPath != null && !device ? (
                  `
              <h5>
                  Click the link below to download the app on your Android device:
              </h5>
              <p>
                  Android Link: <a href="${androidPath}">${androidPath}</a>
              </p><br>
          `
                ) : (
                  '<br>'
                )}
      
  
          <p>
          If you have any questions or concerns regarding the license terms, feel free to contact us.
          </p>
          <p>Thank you once again for choosing us for your software solutions provider. We value your business and look forward to a successful partnership.
          </p><br>
           
          Best Regards,
      <br><br>
      <p>Vaanam Technologies</p>
      <p>
      [Dannie : 90430 26014 ]</p>
              `

              const data = {
                emailId: email,
                message: message,
                subject: 'Congratulations on Your Successful Procurement of Software License!'
              }

              // Convert the object to a JSON string
              const jsonData = JSON.stringify(data);

              // Store the JSON string in local storage
              localStorage.setItem('EmailMessage', jsonData);


              const res = await PurhcaseServiceObj.sendEmail(data);
            }


            a.style.display = "none";
            toast.success(
              'Order Created Successfully',
              {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
              }
          );
          } else {
            a.style.display = "none";
            toast.success(
              'Order Updated Successfully',
              {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
              }
          );
          }
          $(".close").trigger("click");

          setTimeout(() => {
            window.location.reload();
        }, 1500);
        })
        .catch((error) => {
          a.style.display = "none";
          console.log(error);
        });
    }
    // }
  };
  const clearForm = (e) => {
    e.preventDefault();
    window.location.reload();
  };
  return (
    <div>
      <form
        id="AddOrEditPurhcase"
        onKeyPress={(e) => {
          if (e.key == "Enter") {
            e.preventDefault();
          }
        }}
        onSubmit={onSubmit}
      >
        <div class="row">
          <div class="col-sm-4">
            <div class="form-group ">
              <label>
                Product <span className="text-danger">*</span>
              </label>

              <select
                class="form-select product"
                name="product"
                value={PurchaseModel.productId}
                onChange={ProductIdchange}
              >
                <option value="0">--Select--</option>
                {ProductModel != null && ProductModel.length > 0 ? (
                  ProductModel.map((e) => {
                    return (
                      <option value={e?.productId}>
                        {e?.productName}
                      </option>
                    );
                  })
                ) : (
                  <></>
                )}
              </select>
              <span className="text-danger" id="erruserId">
                {error.product}
              </span>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group ">
              <label>
                Customer <span className="text-danger">*</span>
              </label>

              <select
                class="form-select"
                name="userId"
                value={customerId != null && customerId != undefined ? customerId : PurchaseModel.userId}
                onChange={handleChange}
                disabled={customerId != null && customerId != undefined ? true : false}
              >
                <option value="0">--Select--</option>
                {CustomerModel != null && CustomerModel.length > 0 ? (
                  CustomerModel.map((e) => {
                    return (
                      <option value={e?.customerId}>
                        {e?.customerName}
                      </option>
                    );
                  })
                ) : (
                  <></>
                )}
              </select>
              <span className="text-danger" id="erruserId">
                {error.userId}
              </span>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group ">
              <label>
                Product Version <span className="text-danger">*</span>
              </label>

              <select
                class="form-select productVersionId"
                name="productVersionId"
                value={PurchaseModel.productVersionId}
                onChange={handleChange}
              >
                <option value="0">--Select--</option>
                {ProductVersionModel != null && ProductVersionModel.length > 0 ? (
                  ProductVersionModel.map((e) => {
                    return (
                      <option value={e.productVersionId}>
                        {e.version}
                      </option>
                    );
                  })
                ) : (
                  <></>
                )}



              </select>
              <span className="text-danger" id="errproductPlanId">
                {error.productVersionId}
              </span>
            </div>
          </div>
          <div class="col-sm-6 mt-2">
            <div class="form-group ">
              <label>
                Subscription <span className="text-danger">*</span>
              </label>
              <select
                class="form-select productPlanId"
                name="productPlanId"
                value={packageId}
                onChange={handleChange}
              >
                <option value="0">--Select--</option>
                {packagesList != null && packagesList.length > 0 ? (
                  packagesList.map((e) => {
                    return (
                      <option value={e.value} data-key={e.key}>
                        {e.label}
                      </option>
                    );
                  })
                ) : (
                  <></>
                )}
              </select>
              <span className="text-danger" id="errproductPlanId">
                {error.productPlanId}
              </span>
            </div>
          </div>
          {WindowsDevice ? <div class="col-sm-4 mt-2">
            <div class="form-group ">
              <label>
                License Key  <span className="text-danger"></span>
              </label>

              <input
                type="text"
                className="form-control"
                maxLength={100}
                placeholder="StartDate"
                name="startDate"
                id="startDate"
                value={LicenseKey}
                autoComplete="off"
                disabled={true}
              />
              {/* <span className="text-danger" id="errproductPlanId">
                {error.productPlanId}
              </span> */}
            </div>
          </div> : <></>}
          {device && <div class="col-sm-6 mt-2">
            <div class="form-group ">
              <label>
                Valid Start Date   <span className="text-danger"></span>
              </label>

              <input
                type="datetime-local"
                className="form-control"
                maxLength={100}
                placeholder="StartDate"
                name="startDate"
                id="startDate"
                defaultValue={startDate}
                onChange={handleDateChange}
                autoComplete="off"
              />
              {/* <span className="text-danger" id="errproductPlanId">
                {error.productPlanId}
              </span> */}
            </div>
          </div>
          }
        </div>


        <div class="modal-footer no-bd mt-4">
          <button type="submit" id="PurchaseSubmit" class="btn btn-primary">
            Submit
          </button>
          {purchaseId > 0 ?
            <button type="button" id="addRenewButton" class="btn btn-primary" style={{ display: 'none' }} onClick={onRenew}>
              Renew
            </button>
            : ""}

          <button
            type="button"
            class="btn btn-danger"
            data-dismiss="modal"
            onClick={clearForm}
          >
            Close
          </button>
        </div>
      </form >
      <ToastContainer />
    </div >
  );
}

export default AddOrEditPurhcase;
