import AxiosService from "./AxiosService";

const axios = new AxiosService();
export default class Customers_Services {
    getCustomerList = async () => {
        return await axios.get("Customer/Customer_getList").then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    getCustomerListByID = async (customerID) => {
        return await axios.get(`Customer/Customers_getListById?customerID=${customerID}`).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    Customers_AddUpdate = async (data) => {
        return await axios.post("Customer/Customers_AddUpdate", data).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    Delete_Customers = async (CustomerID) => {
        return await axios.post(`Customer/Delete_Customers?CustomerID=${CustomerID}`).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
        });
    }
}