import UserImage from '../assets/images/profile/user-1.jpg';
import UserImage2 from '../assets/images/profile/user-2.jpg';
import Logo from "../assets/images/logos/vaanamwhiteLogo.png";
import { Link, useNavigate } from "react-router-dom";
import Account from "../assets/images/svgs/icon-account.svg";
import { Mail, Menu, Notifications, NotificationsPaused, } from '@mui/icons-material';
const Header = () => {
    const navigate = useNavigate();
    function Logout() {
        localStorage.clear();
        navigate("/", { replace: true });
       
      }
    return (
        <header className="app-header">
            <nav className="navbar navbar-expand-lg navbar-dark">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link sidebartoggler nav-icon-hover ms-n3" id="headerCollapse">
                            {/* <i className="ti ti-menu-2" /> */}
                            <Menu />
                        </a>
                    </li>
                    {/* <li className="nav-item d-none d-lg-block">
                        <a className="nav-link nav-icon-hover" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            <i className="ti ti-search" />
                        </a>
                    </li> */}
                </ul>
                <div className="d-block d-lg-none">
                    <img src={Logo} alt="" />
                </div>
                <button className="navbar-toggler p-0 border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded={false} aria-label="Toggle navigation">
                    <span className="p-2">
                        <i className="ti ti-dots fs-7" />
                    </span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <div className="d-flex align-items-center justify-content-between">
                        <a href="#" className="nav-link d-flex d-lg-none align-items-center justify-content-center" type="button" data-bs-toggle="offcanvas" data-bs-target="#mobilenavbar" aria-controls="offcanvasWithBothOptions">
                            <i className="ti ti-align-justified fs-7" />
                        </a>
                        <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-center">
                            <li className="nav-item dropdown">

                                <div className="dropdown-menu dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="drop2">
                                    <div className="message-body" data-simplebar>
                                        <a href="#" className="d-flex align-items-center gap-2 py-3 px-4 dropdown-item">
                                            <div className="position-relative">
                                                <img src="images/icon-flag-en.svg" alt="" className="rounded-circle object-fit-cover round-20" />
                                            </div>
                                            <p className="mb-0 fs-3">English (UK)</p>
                                        </a>
                                        <a href="#" className="d-flex align-items-center gap-2 py-3 px-4 dropdown-item">
                                            <div className="position-relative">
                                                <img src="images/icon-flag-cn.svg" alt="" className="rounded-circle object-fit-cover round-20" />
                                            </div>
                                            <p className="mb-0 fs-3">中国人 (Chinese)</p>
                                        </a>
                                        <a href="#" className="d-flex align-items-center gap-2 py-3 px-4 dropdown-item">
                                            <div className="position-relative">
                                                <img src="images/icon-flag-fr.svg" alt="" className="rounded-circle object-fit-cover round-20" />
                                            </div>
                                            <p className="mb-0 fs-3">français (French)</p>
                                        </a>
                                        <a href="#" className="d-flex align-items-center gap-2 py-3 px-4 dropdown-item">
                                            <div className="position-relative">
                                                <img src="images/icon-flag-sa.svg" alt="" className="rounded-circle object-fit-cover round-20" />
                                            </div>
                                            <p className="mb-0 fs-3">عربي (Arabic)</p>
                                        </a>
                                    </div>
                                </div>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link notify-badge nav-icon-hover" href="#" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                                    <i className="ti ti-basket" />
                                    <span className="badge rounded-pill bg-danger fs-2">2</span>
                                </a>
                            </li> */}
                            <li className="nav-item dropdown">
                                <a className="nav-link nav-icon-hover" id="drop2" data-bs-toggle="dropdown" aria-expanded={false}>
                                    {/* <i className="ti ti-bell-ringing" /> */}
                                    <Notifications />
                                    <div className="notification bg-primary rounded-circle" />
                                </a>
                                <div className="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="drop2">
                                    {/* <div className="d-flex align-items-center justify-content-between py-3 px-7">
                                        <h5 className="mb-0 fs-5 fw-semibold">Notifications</h5>
                                        <span className="badge bg-primary rounded-4 px-3 py-1 lh-sm">2 new</span>
                                    </div>
                                    <div className="message-body" data-simplebar>
                                        <a href="#" className="py-6 px-7 d-flex align-items-center dropdown-item">
                                            <span className="me-3">
                                                <img src={UserImage} alt="user" className="rounded-circle" width={48} height={48} />
                                            </span>
                                            <div className="w-75 d-inline-block v-middle">
                                                <h6 className="mb-1 fw-semibold">Roman Joined the Team!</h6>
                                                <span className="d-block text-muted">Congratulate him</span>
                                            </div>
                                        </a>
                                        <a href="#" className="py-6 px-7 d-flex align-items-center dropdown-item">
                                            <span className="me-3">
                                                <img src={UserImage2} alt="user" className="rounded-circle" width={48} height={48} />
                                            </span>
                                            <div className="w-75 d-inline-block v-middle">
                                                <h6 className="mb-1 fw-semibold">New message</h6>
                                                <span className="d-block text-muted">Salma sent you new message</span>
                                            </div>
                                        </a>

                                    </div>
                                    <div className="py-6 px-7 mb-1">
                                        <button className="btn btn-outline-primary w-100"> See All Notifications </button>
                                    </div> */}
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link pe-0" id="drohjgiup1" data-bs-toggle="dropdown" aria-expanded={false}>
                                    <div className="d-flex align-items-center">
                                        <div className="user-profile-img">
                                            <img src={UserImage} className="rounded-circle" width={35} height={35} alt="" />
                                        </div>
                                    </div>
                                </a>
                                <div className="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="drohjgiup1">
                                    <div className="profile-dropdown position-relative" data-simplebar>
                                        <div className="py-3 px-7 pb-0">
                                            <h5 className="mb-0 fs-5 fw-semibold">User Profile</h5>
                                        </div>
                                        <div className="d-flex align-items-center py-9 mx-7 border-bottom">
                                            <img src={UserImage} className="rounded-circle" width={80} height={80} alt="" />
                                            <div className="ms-3">
                                                <h5 className="mb-1 fs-3">Mathew Anderson</h5>
                                                <span className="mb-1 d-block text-dark">Designer</span>
                                                <p className="mb-0 d-flex text-dark align-items-center gap-2" style={{ fontSize: "11px" }}>
                                                    {/* <i className="ti ti-mail fs-4" />  */}
                                                    <Mail className='fs-4' />
                                                    info@Vaanam Technologies.com
                                                </p>
                                            </div>
                                        </div>
                                        <div className="message-body">
                                            <a href="#" className="py-8 px-7 mt-8 d-flex align-items-center">
                                                <span className="d-flex align-items-center justify-content-center bg-light rounded-1 p-6">
                                                    <img src={Account} alt="" width={24} height={24} />
                                                </span>
                                                <div className="w-75 d-inline-block v-middle ps-3">
                                                    <h6 className="mb-1 bg-hover-primary fw-semibold"> My Profile </h6>
                                                    <span className="d-block text-dark">Account Settings</span>
                                                </div>
                                            </a>

                                        </div>
                                        <div className="d-grid py-4 px-7 pt-8">
                                            <a href="#"  onClick={() => Logout()}  className="btn btn-outline-primary">Log Out</a>
                                   
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}
export default Header;