import "jquery/dist/jquery.js";
import "bootstrap/dist/js/bootstrap.bundle.js";
import './assets/css/style-dark.min.css';
import './assets/css/owl.carousel.min.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from './Module/Login';
import ProductDetails from './Module/ProductList/ProductList.js';
import SideBar from './Module/SideBar.js';
import Dashboard from './Module/Dashboard.js';
import "./assets/js/custom.js";
import "./assets/js/app-style-switcher.js";
import "./assets/js/app.dark.init.js";
import "./assets/js/app.min.js";
import "./assets/js/sidebarmenu.js";
import "./Module/Customer/CustomerList";
import './assets/js/contact.js';
import FAQ from './Module/FAQ.js';
import Account from './Module/Accountsetting.js';
import Error from './Module/Error.js';
import ForgotPassword from './Module/Password/ForgotPassword.js';
import ModalPopup from './Components/Common/ModalPopup';
import CustomerList from "./Module/Customer/CustomerList";
import Home from "./Module/Home.js";
import Versions from "./Module/VersionPage.js";
import { useState, useContext, useEffect } from "react";
import { AppContext } from "../src/Module/Api/ContextProvider";
import Associate from "../src/Module/Associate/MainGrid"
import PurchaseGrid from "../src/Module/Purchase/PurchaseGrid"
import {
  Encrypt,
  Decrypt,
} from "./Module/Api/CommonService";
import ProductList from "./Module/ProductList/ProductList.js";
import ProductLink from "./Module/ProductList/ProductLink.js";
import ClientPurchase from "./Module/Purchase/ClientPurchaseView.js";
import WebApplicationDemo from "./Module/ProductList/WebApplicationDemo.js";
import Configuration from "./Module/Config/Configuration.js";

function App() {
  const { showLoading, hideLoading, setCookies, tempModulePrivileage, isAuth, SetisAuth } =
    useContext(AppContext);
  useEffect(() => {

    showLoading();
    if (localStorage.getItem("IsAuth") != null) {

      SetisAuth(true);
    } else {
      SetisAuth(false);
    }
    hideLoading();
  }, [localStorage.getItem("IsAuth")]);
  return (

    <Router>
      <Routes>
        {localStorage.IsAuth ?
          <Route element={<SideBar />}>

            <Route path="/product" element={<ProductDetails />}></Route>
            <Route path="/dashboard" element={<Dashboard />}></Route>
            <Route path="/customerList" element={<CustomerList />}></Route>
            <Route path="/faq" element={<FAQ />}></Route>
            <Route path="/account" element={<Account />}></Route>
            <Route path="/productList" element={<ProductList />}></Route>
            <Route path="/home" element={<Home />}></Route>
            <Route path="/versions" element={<Versions />}></Route>
            <Route path="/Associate" element={<Associate />}></Route>
            <Route path="/PurchaseGrid" element={<PurchaseGrid />}></Route>
            <Route path="/configure" element={<Configuration />}></Route>



          </Route>
          : ""}
      </Routes>
      <Routes>
        <Route exact path="/" element={<Login />}></Route>
        <Route path="/forgot" element={<ForgotPassword />}></Route>
        <Route path="/error" element={<Error />}></Route>
        <Route path="/modal" element={<ModalPopup />}></Route>
        <Route path="/ProductsServe" element={<ProductLink />}></Route>
        <Route path="/Web" element={<WebApplicationDemo />}></Route>
        <Route path="/Client" element={<ClientPurchase />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
