import ModalPopup from "../../Components/Common/ModalPopup";
import AddUpdateCustomer from "./AddupdateCustomer";
import AddUpdateOrders from "../OrderList/AddUpdateOrders";
import Customers_Services from "../Api/Customers_Services";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { ArrowLeft, ArrowRight, Delete, Email, Person2, Person2Outlined, RemoveRedEyeOutlined, Search, ShoppingCartCheckout } from "@mui/icons-material";
import AddOrEditPurhcase from "../Purchase/AddOrEditPurchase";
import PurhcaseService from "../Api/PurchaseService";
import Toast from "../../Components/Toastify";
import PackageService from "../Api/PackageService";

const PurhcaseServiceObj = new PurhcaseService();
const packObj = new PackageService();
const customerObj = new Customers_Services();
const CustomerList = () => {
    const [getCustomerList, setCustomerList] = useState([]);
    const [getCustomerID, setCustomerID] = useState(0);
    const [currentPage, setCurrentPage] = useState(0); // Current page
    const [pageNumber, setPageNumber] = useState(5);
    const itemsPerPage = parseInt(pageNumber); // Number 
    // let retrievedData;
    const [retrievedData, setRetriveData] = useState({});

    useEffect(() => {
        get_email();
        Customer_getList()
    }, [])


    const Customer_getList = async () => {
        await customerObj.getCustomerList().then((res) => {
            if (res != null) {

                setCustomerList(res);
            }
        }).catch((err) => {

        });
    }


    const handleEdit = (customerID) => {

        setCustomerID(customerID);
    }
    const getItemsForCurrentPage = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return getCustomerList.slice(startIndex, endIndex);
    };
    const itemsToDisplay = getItemsForCurrentPage();

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const Delete_Customers = async (customerID) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want delete this customer?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, ignore'
        }).then((result) => {
            if (result.isConfirmed) {
                customerObj.Delete_Customers(customerID).then(async (res) => {
                    if (res != null) {
                        
                        const a = await packObj.Inactive_Customer(customerID);
                        if (a?.data > 0) {
                            console.log('Success')
                        }
                        else {
                            console.log('error');
                        }
                        toast.success(
                            'Customer Deleted Succesfully',
                            {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            }
                        );
                        setTimeout(() => {
                            window.location.reload();
                        }, 1500);
                    }
                    else {
                        toast.error(
                            'Failed to Delete',
                            {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            }
                        );
                    }
                })

            }
        })


    }

    const get_email = async () => {

        const storedData = localStorage.getItem('EmailMessage');
        if (storedData) {
            // retrievedData = JSON.parse(storedData);
            setRetriveData(JSON.parse(storedData));
        }
    }

    const Resend_email = async (data) => {

        const a = document.getElementById('spin');
        a.style.display = "block";
        const res = await PurhcaseServiceObj.sendEmail(data);

        if (res.data == true) {
            a.style.display = "none";
            Toast("Email sent successfully", "success");
        }
        else {
            a.style.display = "none";
            Toast("Failed to sent", "error");
        }
    }

    return (
        <>

            <div className="card bg-light-info shadow-none position-relative overflow-hidden">
                <div className="card-body px-4 py-3">
                    <div className="row align-items-center">
                        <div className="col-9">
                            <h4 className="fw-semibold mb-8">Customers List</h4>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a className="text-muted " href="/home">Home</a></li>
                                    <li className="breadcrumb-item" aria-current="page">Customers List</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-3">
                            <div className="text-center mb-n5">
                                <img alt="" className="img-fluid mb-n4" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="widget-content searchable-container list">
                {/* --------------------- start Contact ---------------- */}
                <div className="card card-body">
                    <div className="row">
                        <div className="col-md-4 col-xl-3">
                            <form className="position-relative">
                                <input type="text" className="form-control product-search ps-5" id="input-search" placeholder="Search Customers..." />
                                {/* <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3" /> */}
                                <Search className="position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3" />

                            </form>
                        </div>
                        <div className="col-md-8 col-xl-9 text-end d-flex justify-content-md-end justify-content-center mt-3 mt-md-0">
                            <div className="action-btn show-btn" style={{ display: 'none' }}>
                                <a href="#" className="delete-multiple btn-light-danger btn me-2 text-danger d-flex align-items-center font-medium">
                                    {/* <i className="ti ti-trash text-danger me-1 fs-5" /> */}
                                    <Delete className="text-danger me-1 fs-5" />
                                    Delete All Row
                                </a>
                            </div>
                            <a href="#" id="btn-add-contact" className="btn btn-info d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#addContactModal">
                                {/* <i className="ti ti-users text-white me-1 fs-5" /> */}
                                <Person2Outlined className="ti ti-users text-white me-1 fs-5" />
                                Add Customer
                            </a>
                        </div>
                    </div>
                </div>
                {/* ---------------------
                              end Contact
                          ---------------- */}
                {/* Modal */}

                <div className="card card-body">
                    <div className="table-responsive">
                        <table className="table search-table align-middle text-nowrap">
                            <thead className="header-item">
                                <tr>
                                    {/* <th>
                                    <div className="n-chk align-self-center text-center">
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input primary" id="contact-check-all" />
                                            <label className="form-check-label" htmlFor="contact-check-all" />
                                            <span className="new-control-indicator" />
                                        </div>
                                    </div>
                                </th> */}
                                    <th>Company Name</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Location</th>
                                    <th>Phone</th>
                                    <th>Action</th>
                                </tr></thead>
                            <tbody>
                                {/* start row */}
                                {itemsToDisplay?.length > 0 && itemsToDisplay.map((e, i) => {

                                    const isEmailMatch = retrievedData?.emailId && e?.email === retrievedData?.emailId;

                                    return <tr className="search-items" >
                                        {/* <td>
                                            <div className="n-chk align-self-center text-center">
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input contact-chkbox primary" id={`checkbox${i + 1}`} />
                                                    <label className="form-check-label" htmlFor={`checkbox${i + 1}`} />
                                                </div>
                                            </div>
                                        </td> */}
                                        <td>
                                            <span className="usr-email-addr" data-email="adams@mail.com">{e?.companyName}</span>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                {/* <img src={userImage1} alt="avatar" className="rounded-circle" width={35} /> */}
                                                <div>
                                                    <div className="user-meta-info">
                                                        <h6 className="user-name mb-0" data-name="Emma Adams">{e?.customerName}</h6>
                                                        <p className="fs-2 mb-0 text-muted">{e?.companyName}</p>                                                </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span className="usr-email-addr" data-email="adams@mail.com">{e?.email}</span>
                                        </td>
                                        <td>
                                            <span className="usr-location" data-location="Boston, USA">{e?.address}</span>
                                        </td>
                                        <td>
                                            <span className="usr-ph-no" data-phone="+1 (070) 123-4567">{e?.contactNumber}</span>
                                        </td>
                                        <td>
                                            <div className="action-btn">
                                                <a href="#" className="text-danger edit" data-bs-toggle="modal" data-bs-target="#addorderModal" onClick={() => handleEdit(e?.customerId)}>
                                                    {/* <i className="ti ti-shopping-cart fs-5" /> */}
                                                    <ShoppingCartCheckout />
                                                </a>
                                                <a href="#" className="text-info edit ms-2" data-bs-toggle="modal" data-bs-target="#addContactModal" onClick={() => handleEdit(e?.customerId)}>
                                                    {/* <i className="ti ti-eye fs-5" /> */}
                                                    <RemoveRedEyeOutlined className="fs-5" />
                                                </a>
                                                <a href="#" className="text-dark delete ms-2" onClick={() => Delete_Customers(e?.customerId)}>
                                                    {/* <i className="ti ti-trash fs-5" /> */}
                                                    <Delete className="fs-5" />
                                                </a>
                                                {isEmailMatch ? <a href="#" className="text-dark delete ms-2" onClick={() => Resend_email(retrievedData)} >
                                                    {/* <i className="ti ti-trash fs-5" /> */}
                                                    <Email className="fs-5" />
                                                </a> : null}

                                            </div>
                                        </td>
                                    </tr>
                                })}

                                {/* end row */}
                                <ToastContainer />
                            </tbody>
                        </table>
                        <div className="d-flex align-items-center justify-content-end py-1">
                            <p className="mb-0 fs-2">
                                Rows per page:
                            </p>
                            <select
                                className="form-select w-auto ms-0 ms-sm-2 me-8 me-sm-4 py-1 pe-7 ps-2 border-0"
                                aria-label="Items per page"
                                onChange={(e) =>
                                    setPageNumber(e.target.value)
                                    // You can update the itemsPerPage state here
                                }
                            >
                                <option value="5" selected>5</option>
                                <option value="10">10</option>
                                <option value="25">25</option>
                            </select>
                            <p className="mb-0 fs-2">
                                {currentPage * itemsPerPage + 1} -{" "}
                                {Math.min((currentPage + 1) * itemsPerPage, getCustomerList.length)} of{" "}
                                {getCustomerList.length}
                            </p>
                            <nav aria-label="...">
                                <ul className="pagination justify-content-center mb-0 ms-8 ms-sm-9">
                                    <li className="page-item p-1">
                                        <a
                                            className="page-link border-0 rounded-circle text-dark fs-6 round-32 d-flex align-items-center justify-content-center"
                                            href="#"
                                            onClick={() => {
                                                if (currentPage > 0) {
                                                    handlePageChange(currentPage - 1);
                                                }
                                            }}
                                        >
                                            {/* <i className="ti ti-chevron-left" /> */}
                                            <ArrowLeft />
                                        </a>
                                    </li>
                                    <li className="page-item p-1">
                                        <a
                                            className="page-link border-0 rounded-circle text-dark fs-6 round-32 d-flex align-items-center justify-content-center"
                                            href="#"
                                            onClick={() => {
                                                if (currentPage < Math.ceil(getCustomerList.length / itemsPerPage) - 1) {
                                                    handlePageChange(currentPage + 1);
                                                }
                                            }}
                                        >
                                            {/* <i className="ti ti-chevron-right" /> */}
                                            <ArrowRight />
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                    </div>
                </div>
            </div >

            {/* <AddUpdateCustomer getCustomerID={getCustomerID} /> */}
            < ModalPopup DataModal={
                < AddUpdateCustomer getCustomerID={getCustomerID} />} id={"addContactModal"} Title={"Customer"} form={"addContactModalTitle"} />
            <ModalPopup DataModal={<AddOrEditPurhcase purchaseId={0} customerId={getCustomerID} />} id={"addorderModal"} Title={"Orders"} form={"addOrderModalTitle"} size={"Orders"} />

        </>


    );
}
export default CustomerList;